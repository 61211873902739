<template>
<div>
    <smallbutton toplabel="Add Document" @click="startAdd()" v-if="showButton" />
    <linkbutton toplabel="Add Document" @click="startAdd()" v-if="!showButton"/>
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="balform">
                <label class="titlelabel">Add Document for {{AccountDocument.Name}}</label>
                <br/>
                
                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Document Type</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="AccountDocument.accountDocumentTypeId" required>
                        <option v-for="dType in documentTypeList" :key="dType.id" :value="dType.id" >
                            {{dType.name}}
                        </option>
                    </select>
                </div>

                
                <formentry inputtype="date"  label="Effective Date"  v-model="AccountDocument.effectiveDate" isrequired='True'/>
                <formentry   label="Notes"  v-model="AccountDocument.Reason" isrequired="True" />
                
                <div>
                    <label for="" class="required">{{requiredFieldLabel}}</label>
                </div>
                <label class="picButton">
                    <div class="picButtonDiv">
                        <input type="file"  capture @change="onFileChanged"  style="display:none;">
                        <p>Select Document</p>      
                    </div>
                </label>
                <div>
                    <img alt="doc image"  :src="capturedDocumentURL" class="docimage" v-if="capturedDocumentURL!=null"/>
                </div>
                
                <smallbutton  @click="ConfirmAddTransaction()" toplabel="Save" />
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
                
            </form>
            
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import {mapFields} from 'vuex-map-fields'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import linkbutton from '../controls/linkbutton.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
export default {
    name:'adddoctrans',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton
    },
    props:{
        account:{
            type:Object,
            required:true
        },
        showButton:{
            type:Boolean,
            default:false
        }
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            
            responseMessage:'',
            pleaseWait:false,
            AccountDocument:{
                Name:'',
                accountId:0,
                accountDocumentTypeId:0,
                fileName:'',
                notes:'',
                effectiveDate:'',
            },
            documentTypeList:[],
            capturedDocument:null,
            capturedDocumentURL:null,
            requiredFieldLabel:'* required'
            
        }
    },
    computed:{
        ...mapFields([
            'disableAddFunds',
            'currentWallet'

        ]),
        
    },
    mounted(){
        this.GetDocumentTypes()
    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        onFileChanged(event){
            this.capturedDocument = event.target.files[0];
            this.capturedDocumentURL = URL.createObjectURL(this.capturedDocument);

        },
      
        startAdd(){
            var dt = this.$options.filters.formatDateForInputToday();
            this.AccountDocument={
                Name:'',
                accountId:0,
                accountDocumentTypeId:0,
                notes:'',
                effectiveDate:dt,
            }   
            this.AccountDocument.Name=this.account.Name;
            this.AccountDocument.accountId=this.account.ID;
            this.$refs.mymodal.openModal()
        },
        async ConfirmAddTransaction(){
            //if(this.$refs.balform.checkValidity()){
                this.beforeAPI();
                
                var postParams = new FormData();

                //var fileblob = await this.resizeImage(this.capturedDocument,180)
                postParams.append(this.AccountDocument.accountDocumentTypeId,this.capturedDocument,this.capturedDocument.name);
                postParams.append('accountDocumentTypeId',this.AccountDocument.accountDocumentTypeId);
                postParams.append('notes',this.AccountDocument.notes);
                postParams.append('effectiveDate',this.AccountDocument.effectiveDate);
                postParams.append('accountId',this.AccountDocument.accountId);
                postParams.append('fileName',this.capturedDocument.name);
                var response = await this.callAPI(postParams,'adddoctransaction')

                if(response.Successful){
                    this.responseMessage="Add Document Transaction was successful"
                this.$emit('refresh')
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
           // }
        },

       async GetDocumentTypes(){
            
                this.beforeAPI();
                var response = await this.callAPI(null,'documenttypes')

                if(response.Successful){
                    this.documentTypeList=response.DocumentTypeList
                
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
           
        },
        
       
        
    }
}
</script>

<style>
.docimage{
    height:80px;
}
.picButton{
    height:50px;
    width:100px;
    text-decoration: underline;
}
.picButton:hover{
    color:var(--highlight-color);
    cursor:pointer;
}
</style>





























