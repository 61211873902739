<template>
  <div class="institutionedge">
        <div  v-if="institution!=undefined">
            <div class="horizFlex" >
                <label for="" class="labelBold right10">{{institution.name}} </label>
                <updateinstitution :institution="institution" @refresh="getData()"/>
            </div>
             <labeledlist :dataList="getDetail()" showNulls=true />  
             <labeledlist :dataList="getDetail2()" showNulls=true />  
             <labeledlist :dataList="getDetail3()" showNulls=true />  
        </div>
     
            
        <div v-if="responseMessage!=null">
            <label class="responsemessage">{{responseMessage}}</label>
        </div>
    </div>
    
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import updateinstitution from '../actions/updateinstitution.vue'
import labeledlist from '../controls/labeledlist.vue'

export default {
    name:"institution",
    mixins:[datamethods,format],
    components:{
        updateinstitution,
        labeledlist
    },
    props:{
        
    },
    data() {
        return {
            institution:{},

            responseMessage:''
        };
    },
    mounted(){
        this.getData()
    },
    computed:{

        ...mapFields([
            'loggedInUser.isAdmin',

        ]),

    },
    methods:{
        getDetail(){
            var detail = [
                {
                    label:'Address 1',
                    value:this.institution.AddressLine1
                },
                {   
                    label:'Address 2',
                    value:this.institution.AddressLine2
                },
                {   
                    label:'City',
                    value:this.institution.City
                },
                {   
                    label:'State',
                    value:this.institution.StateName
                },
                {   
                    label:'Zip',
                    value:this.institution.ZipCode
                },
                

                
            ]
            return detail
        },
        getDetail2(){
            var detail = [
                
                {   
                    label:'Contact Name',
                    value:this.institution.ContactName
                },
                {   
                    label:'Contact Email',
                    value:this.institution.ContactEmailAddress
                },
                {   
                    label:'Contact Phone',
                    value:this.institution.ContactPhoneNumber
                },
                {   
                    label:'Contact From Name',
                    value:this.institution.ContactFromName
                },
                

                
            ]
            return detail
        },
        getDetail3(){
            var detail = [
               
                {   
                    label:'Deactivate Below',
                    value:this.$options.filters.toCurrency(this.institution.DeactivateBelowBalance )
                },
                {   
                    label:'Minimum Balance Alert',
                    value:this.$options.filters.toCurrency(this.institution.MinimumBalance_Alert)
                },
                {   
                    label:'Minimum Balance Suspend',
                    value:this.$options.filters.toCurrency(this.institution.MinimumBalance_Suspend)
                },
                {   
                    label:'Email Footer',
                    value:this.institution.EmailFooter
                },
                {   
                    label:'Relation To Account',
                    value:this.institution.RelationToAccount_Default
                },
                {   
                    label:'Relation To Wallet',
                    value:this.institution.RelationToWallet_Default
                },

                
            ]
            return detail
        },
        async getData(){
            this.message = ''
            let req = {
                Institution:{
                    id:'0'
                }
            }

            var response = await this.callAPI(req,'institution')    
            if(response.Successful){
                console.log(response)
                this.institution=response.Institution;
            }else{
                this.message = response.Message
            }

        },
    }
}
</script>

<style >

.institutionedge{

    background-color: white;
    border-radius:10px;
    margin:10px;
    padding:10px;
    display:flex;
    flex-direction: column;
    text-align: left;
}

.itemdetail{
    grid-area:detail;
    text-align: left;

}
.labelBold{
  font-size:large;
  font-weight: bold;
}

.detailbutton{
    width:120px;
    margin-left:auto;
}

</style>
