import Vue from 'vue'
import App from './App.vue'
import {store} from './store/store'

import GlobalEvents from 'vue-global-events'
Vue.component('GlobalEvents', GlobalEvents)

Vue.config.productionTip = false

new Vue({
  store,
  render: function (h) { return h(App) },
  methods:{
    /* myBackButton(){
      console.log('backpressed')
    } */
  },
  mounted(){
    
    //document.addEventListener("backbutton",this.myBackButton,false);
  }
}).$mount('#app')
