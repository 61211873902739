<template>
  <div class="transDiv">
        <div class="headers">
            <div class="trans">
                
                <linkbutton  :toplabel="sortTag('dateTime')" @click="sort('dateTime')"/>
                <linkbutton  :toplabel="sortTag('transactionId')" @click="sort('transactionId')"/>
                <linkbutton  :toplabel="sortTag('accountName')" @click="sort('accountName')"/>
                <linkbutton  :toplabel="sortTag('transactionTypeName')" @click="sort('transactionTypeName')"/>
                <linkbutton  :toplabel="sortTag('amount')" @click="sort('amount')"/>
                <linkbutton  :toplabel="sortTag('feeAmount')" @click="sort('feeAmount')"/>
            </div>
        </div>
        
        <div class="scrollDiv">
            <div v-for="trans in sortedTransactionList" :key="trans.transactionId" :value="trans.transactionId"  >
                <div @click="toggleDetail(trans)" :class="transClass(trans)">
                    <label class="itemlabel">{{trans.dateTime | formatDateTime}}</label>
                    <label class="itemlabel">{{trans.transactionId}}</label>
                    <label class="itemlabel">{{trans.accountName}}</label>
                    <label class="itemlabel">{{trans.transactionTypeName}}</label>
                    <label class="itemlabel">{{trans.amount | toCurrency}}</label>
                    <label class="itemlabel">{{trans.feeAmount | toCurrency}}</label>
                </div>
                <!-- <button class="notebutton">Add Note</button> -->
                <div :class="transDetailClass(trans)">
                <div v-if="trans.showDetail" class="transDetail">
                    
                    <transdetail :trans="trans" @gotowallet="gotowallet" @refresh="$emit('refresh')"/>
                    
                </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import transdetail from './transdetail.vue'
import format from '../../format.js'
import linkbutton from '../controls/linkbutton.vue'

import {mapFields} from 'vuex-map-fields'
export default {
    name:'itemwithdetaillist',
    components:{
        transdetail,
        linkbutton,
        
    },
    mixins:[format],
    props:{
        transactionList:{
            type:Array,
    
        },

    },
    data(){
        return{
             sortBy:'dateTime',
            sortDirection:'asc',
            columnCount:6,

           
        }
    },

    computed:{
        ...mapFields([
            'selectedTrans',


        ]),
    
        showButtons(){
            if(Object.keys(this.selectedTrans).length===0){
                return false
            }
            
            return true
        },
        
        sortedTransactionList:function(){
            return this.transactionList.sort((t1,t2)=>{
                let modifier = 1;
                if(this.sortDirection=='desc'){modifier=-1};
                if(t1[this.sortBy] < t2[this.sortBy]) return -1 * modifier; 
                if(t1[this.sortBy] > t2[this.sortBy]) return 1 * modifier;
                return 0
            });
        }
    },
    methods:{
        sortTag(s){
            var sorttitle
            switch (s) {
                case 'transactionId':
                    sorttitle="ID"
                    break;
                case 'dateTime':
                    sorttitle="Date"
                    break;
                case 'accountName':
                    sorttitle="Account"
                    break;
                case 'transactionTypeName':
                    sorttitle = "Transaction Type"
                    break;
                case 'amount':
                    sorttitle = "Amount"
                    break;
                case 'feeAmount':
                    sorttitle = "Fee"
                    break;
                default:
                    break;
            }
            if(s==this.sortBy){
                 if (this.sortDirection=='asc'){sorttitle = sorttitle +  ' +'}
                else{sorttitle = sorttitle + ' -'}
            }
        
            return sorttitle
        },
        sort(s){
            if(s==this.sortBy){
                if(this.sortDirection =='asc') {
                    this.sortDirection='desc'
                } else{this.sortDirection='asc'}
            }
            this.sortBy=s;
        },
        toggleDetail(trans){
            trans.showDetail=!trans.showDetail
            if(trans.showDetail){this.selectedTrans=trans}
            else{this.selectedTrans={}}
        },
        transClass(trans){
         
            
            if(trans.showDetail){
                if(!trans.isActive){return ' trans lighttanborderedbar'}
                else{
                    switch (trans.groupName) {
                        case 'Addition':
                            return 'trans lightblueborderedbar'
                            break;
                        case 'Deduction':
                            return 'trans lightredborderedbar'
                            break;
                        case 'Payment':
                            return 'trans lightgreenborderedbar'
                            break;
                        case 'Other':
                            return 'trans lighttanborderedbar'
                            break;
                        default:
                            break;
                    }
                }

            }else
            {
                if(!trans.isActive){return ' trans lighttan'}
                else{
                    switch (trans.groupName) {
                        case 'Addition':
                            return 'trans lightblue'
                            break;
                        case 'Deduction':
                            return 'trans lightred'
                            break;
                        case 'Payment':
                            return 'trans lightgreen'
                            break;
                        case 'Other':
                            return 'trans lighttan'
                            break;
                        default:
                            break;
                    }
                }
            }
                

        },
        transDetailClass(trans){
            if(!trans.isActive){return 'lighttanbordered'}
            else{
                switch (trans.groupName) {
                    case 'Addition':
                        return 'lightbluebordered'
                        break;
                    case 'Deduction':
                        return 'lightredlightbluebordered'
                        break;
                    case 'Payment':
                        return 'lightgreenlightbluebordered'
                        break;
                    case 'Other':
                            return 'lighttanbordered'
                            break;
                    default:
                        break;
                }
            }
        },
        refresh(){
            this.selectedTrans={}
            this.$emit('refresh')
        },
        gotowallet(walletId){
            this.$emit('gotowallet',walletId)
        }
    }
}
</script>

<style>
.itemlabel{
    margin:5px;
    font-size:medium;
}

.trans{
    display:grid;
    width:100%;
    grid-template-columns:2fr 1fr 3fr 3fr 1fr 1fr;
    grid-template-rows:auto auto;
    margin-top:3px;
    text-align: left;
}
.transDetail{
    text-align: left;
    grid-row:2;
    grid-column-start: span 6;
}
.activeTrans{
    background-color:white
}
.inactiveTrans{
    background-color:lightgray;
}
.notebutton{
    background-color: transparent;
    color:var(white);
    border: none;
    border-radius:10px;
    width:70px;
    height:25px;
    padding:0px;
    font-size: medium;
    margin:0px;

}
.scrollDiv{

    height:540px;
    overflow-x:hidden;
    overflow-y:scroll;

}
.headers{
    width:96vw;
}

.lightred{
    background-color:rgb(247,204,204);
}
.lighttan{
    background-color:rgb(236,228,213);
}
.lightblue{
    background-color:rgb(173,191,239);
}
.lightgreen{
    background-color:rgb(171,214,174);
}
.lightredbordered{
    border-bottom:3px solid rgb(247,204,204);
    border-left:3px solid rgb(247,204,204);
    border-right:3px solid rgb(247,204,204);
    background-color: white;
    
}
.lighttanbordered{
    border-bottom:3px solid rgb(236,228,213);
    border-right:3px solid rgb(236,228,213);
    border-left:3px solid rgb(236,228,213);
    background-color: white;
}
.lightbluebordered{
    border-bottom:3px solid rgb(173,191,239);
    border-left:3px solid rgb(173,191,239);
    border-right:3px solid rgb(173,191,239);
    background-color: white;
}
.lightgreenbordered{
    border-bottom:3px solid rgb(171,214,174);
    border-left:3px solid rgb(171,214,174);
    border-right:3px solid rgb(171,214,174);
    background-color: white;
}
.lightredborderedbar{
    border-top:3px solid rgb(247,204,204);
    border-left:3px solid rgb(247,204,204);
    border-right:3px solid rgb(247,204,204);
    background-color: white;
    
}
.lighttanborderedbar{
    border-top:3px solid rgb(236,228,213);
    border-right:3px solid rgb(236,228,213);
    border-left:3px solid rgb(236,228,213);
    background-color: white;
}
.lightblueborderedbar{
    border-top:3px solid rgb(173,191,239);
    border-left:3px solid rgb(173,191,239);
    border-right:3px solid rgb(173,191,239);
    background-color: white;
}
.lightgreenborderedbar{
    border-top:3px solid rgb(171,214,174);
    border-left:3px solid rgb(171,214,174);
    border-right:3px solid rgb(171,214,174);
    background-color: white;
}
</style>