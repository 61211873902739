<template>
  <header>
        <div class="logoArea" >
            <img alt="school logo" :src="schoolLogoFilePath"  class="logo" v-if="showHeader">
            <p class="schoolname" v-if="showHeader">{{schoolname}}</p>
        </div>

        <div class="navArea" v-if="loggedIn && loggedInUser.isAdmin">
            <button :class="navButtonClass('wallet')" @click="selectNav('wallet')">
                Wallets 
            </button>
            <button :class="navButtonClass('explorer')" @click="selectNav('explorer')">
                Transactions  
            </button>
            <button :class="navButtonClass('report')" @click="selectNav('report')">
                Reports  
            </button>
            
            <!-- <button :class="navButtonClass('wallet')" @click="selectNav('wallet')">
                <div class="buttoninside">
                    <label class="buttonlabel">Wallets</label>
                    <img src="@/assets/modes/users-group.svg" alt=""  class="modeImage">
                </div>   
            </button>
            <button :class="navButtonClass('explorer')" @click="selectNav('explorer')">
                <div class="buttoninside">
                    <label class="buttonlabel">Transactions</label>
                    <img src="@/assets/modes/money.svg" alt=""  class="modeImage">
                </div>   
            </button>
            <button :class="navButtonClass('report')" @click="selectNav('report')">
                <div class="buttoninside">
                    <label class="buttonlabel">Reports</label>
                    <img src="@/assets/modes/report.svg" alt=""  class="modeImage">
                </div>   
            </button>
            <button :class="navButtonClass('admin')" @click="selectNav('admin')">
                <div class="buttoninside">
                    <label class="buttonlabel">Admin</label>
                    <img src="@/assets/modes/admin.svg" alt=""  class="modeImage">
                </div>   
            </button> -->
            <!-- <button :class="navButtonClass('wallet')" @click="selectNav('wallet')">Wallets</button> -->
            <!-- <button :class="navButtonClass('explorer')" @click="selectNav('explorer')">Transactions</button>
            
            <button :class="navButtonClass('report')" @click="selectNav('report')">Reports</button>
            <button :class="navButtonClass('admin')" @click="selectNav('admin')">Admin</button> -->
        </div>  
        
        <div class="userArea vertFlex rightjustified" v-if="loggedIn && showHeader">
            
                <p class="userlabel bold">{{userTitle}}</p>
                 
           
                 
            <linkbutton @click="logout()" toplabel="Logout" />
            <updatepassword :isAdminChangePassword="false"   :username="username" />
             
            
        </div>
        
    </header>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import linkbutton from './controls/linkbutton.vue'
import updatepassword from './actions/updatepassword.vue'
import datamethods from '../datamethods.js'
export default {
    name: "navheader",
    components:{
        linkbutton,
        updatepassword
    },
    mixins:[datamethods],
    data() {
        return {
            // schoolLogoFilePath:'./../' + window.SCHOOL_LOGO,
            schoolname:window.SCHOOL_NAME,
        };
    },
    computed:{
        ...mapFields([
            'currentNav',
            'loggedIn',
            'loggedInUser',
            'lastRefreshDateTime',
            'username',
            'oldpassword',
            'logoOverride',
            'imageBasePath',
            'showHeader'
        ]),
        userTitle(){
            var title =this.loggedInUser.name
            if(this.loggedInUser.isAdmin){
                title = title + ' ADMIN'
            }
            return title
        },
        schoolLogoFilePath(){
            if(this.logoOverride!=''){
                return  this.imageBasePath + this.logoOverride
            }else{
                return './../' + window.SCHOOL_LOGO
            }

        }
    },
    methods:{
        selectNav(name){
            this.currentNav=name;
        },
        navButtonClass(name){
            if(this.currentNav==name){
                return "selectedNav"
            }else{
                return "unselectedNav"
            }
        },
        logout(){
            this.loggedIn=false;
        },
        async getImageBasePath(){
            //call api
            var response = await this.callAPI_Image(null,'imagepath')
            //handle response
            if(response.Successful){
                this.imageBasePath = response.StringResponse
            }else{
                this.responseMessage = response.Message
            }
        },
    },
    mounted(){
        this.getImageBasePath();
    }
}
</script>

<style>
header{
    grid-area:header;
    padding:10px 20px 10px 10px;
    display:grid;
    grid-template-columns:2fr auto 2fr;
    grid-template-rows:auto;
    grid-template-areas: 
        "logo nav user";
    font-size:medium;
    background-color:var(--header-color);
}
.logo{
    max-width: 100%;
    max-height:75px;
}
.navArea{
    padding:0px;
    margin:0px;
}
.selectedNav{
    color:var(--header-button-text-color);
    background-color:var(--header-button-color);

    border: 4px solid var(--highlight-button-color);
    border-radius:2px;
    width:130px;
    height:50px;
    padding:2px;
    font-size: medium;
     margin:10px;

}
.unselectedNav{
    color:var(--header-button-text-color);
    background-color:var(--header-button-color);
    border: none;
    border-radius:0px;
    width:130px;
    height:50px;
    padding:2px;
    font-size: medium;
    margin:10px;
}
.schoolname{
    font-size:large;
    text-align: center;
    margin:0px 0px 0px 20px;
}
.userlabel{
   
    margin:0px 0px 2px 0px;
    
}
.userArea{
  grid-area:user;
}
.modeImage{
    height:25px;
    margin:5px;
}
.buttoninside{
  display:flex;
  flex-direction: column;
  margin:0px;
    padding:0px;
}
.buttonlabel{
    margin:0px;
}
</style>