<template>
  <div :class="colorclass">
      <label >{{title}}</label>
      <label class="gianttext">{{count}}</label>
      <label >{{amount | toCurrency}}</label>
  </div>
</template>

<script>
import format from '../../format.js'
export default {
    name:'summarybox',
    components:{

    },
    props:{
        title:{
            type:String,
        },
        count:{
            type:Number
        },
        amount:{
            type:Number
        },
        background:{
            type:String
        }
        
    },
    mixins:[format],
    computed:{
        colorclass(){
            switch (this.background) {
                case 'green':
                    return 'filled lightgreen'
                    break;
                case 'blue':
                    return 'filled lightblue'
                    break;
                case 'red':
                    return 'filled lightred'
                    break;
                case 'tan':
                    return 'filled lighttan'
                    break;
                default:
                    break;
            }
        }
    }
}
</script>

<style scoped>
.filled{
    /* border:darkgray 1px solid; */
    display:flex;
    flex-direction: column;
    padding:10px;
    margin:5px;
    min-width:80px;
    
}


.gianttext{
    font-size:xx-large;
}
.lightred{
    background-color:rgb(247,204,204);
}
.lighttan{
    background-color:rgb(236,228,213);
}
.lightblue{
    background-color:rgb(173,191,239);
}
.lightgreen{
    background-color:rgb(171,214,174);
}
</style>