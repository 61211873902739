<template>
<div>

    <linkbutton toplabel="View Receipt" @click="getReceipt()"/>
  <!-- ACCT NOTE MODAL -->
      <modal ref="acctnoteModal">
        <template v-slot:header>
          <div class="receipttitlediv">
            <p class="receipttitle">Receipt for Transaction # {{transactionId}}</p>
          </div>
        </template>
        <template v-slot:body>
          <label class="receipttext">{{receiptText}}</label>
        </template>
        <template v-slot:footer>
          <div class="closeButton">
            <smallbutton  @click="$refs.acctnoteModal.closeModal()" toplabel="Close"/>
          </div>
          <div>
              <label class="responsemessage">{{message}}</label>
            </div>
        </template>
      </modal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import smallbutton from '../controls/button.vue'
import modal from '../controls/modal.vue'
import linkbutton from '../controls/linkbutton.vue'
export default {
    name:'transreceipt',
    components:{
        smallbutton,
        modal,
        linkbutton
    },
    props:{
        transactionId:{
            type:Number,
            required:true
        }
        
    },
    mixins:[datamethods],
    data(){
        return{
            message:'',
            receiptText:''
        }
    },
    methods:{
        async getReceipt(){
            
            this.message=''
            let req = {
                transactionId:this.transactionId
            }
            console.log('re ' + this.transactionId)
            var response = await this.callAPI(req,'receipt')

            if(response.Successful){
                this.receiptText=response.StringResponse
                this.$refs.acctnoteModal.openModal()
            }else{
                this.message = response.Message
            }
            
        },
        
    }
}
</script>


<style>
.closeButton{
    display:flex;
    flex-direction: row-reverse;
}
.receipttext{
    font-size:medium;
    white-space:pre-wrap;
    text-align: center;
}
.receipttitle{
    font-size:large;
    text-align: center;
}
.receipttitlediv{
    text-align:center;
}
</style>