<template>
<div>
    <smallbutton toplabel="Move Funds" @click="startMoveFunds()" class="button buttonpay"/>
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="movefundsform">
                <label class="titlelabel">Move Funds Between Accounts</label>
                <br/>
                
                <div class="entry">
                <div class="entryTextDiv">
                <label class="entryLabel" for="">Move From Account</label>
                </div>
                <select name="" id="" class='entrySelect' v-model="AdminFundRequest.fromAccount" required>
                    <option v-for="account in currentWallet.accountList" :key="account.ID" :value="account.ID" >
                        {{account.Name}} # {{account.ID}} {{account.currentBalance | toCurrency}}
                    </option>
                </select>
            
                </div>

                <div class="entry">
                <div class="entryTextDiv">
                <label class="entryLabel" for="">Move To Account</label>
                </div>
                <select name="" id="" class='entrySelect' v-model="AdminFundRequest.toAccount" required>
                    <option v-for="account in currentWallet.accountList" :key="account.ID" :value="account.ID" >
                        {{account.Name}} # {{account.ID}} {{account.currentBalance  | toCurrency}}
                    </option>
                </select>
                </div>

                <formentry inputtype="number" isrequired="True" label="Amount to Move $"  v-model="AdminFundRequest.oldAmount" />
                <formentry   label="Reason"  v-model="AdminFundRequest.Reason" />

                <smallbutton @click="ConfirmMoveFunds()" toplabel="Move Funds"/>
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
              
                
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
            </form>
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
export default {
    name:'movefunds',
    components:{
        smallbutton,
        actionmodal,
        formentry
    },
    props:{

        
    },
    mixins:[datamethods,format],
    data(){
        return{
            
            responseMessage:'',
            pleaseWait:false,

            AdminFundRequest:{
                Name:'',
                fromAccount:0,
                toAccount:0,
                oldAmount:'',
                newAmount:'',
                Reason:''
            },
            accountListAccess:[],
            lookupWalletEmail:'',
            lookupWalletID:'',
            walletresults:[],
            showAfterGrantButtons:false,
            accountToRemain:0,
        }
    },
    computed:{
        ...mapFields([
            'currentWallet',

        ]),
        
    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        startMoveFunds(){
            this.AdminFundRequest={
                Name:'',
                fromAccount:0,
                toAccount:0,
                oldAmount:'',
                newAmount:'',
                Reason:''
            }        

            this.$refs.mymodal.openModal()
            this.responseMessage=''
        },
       async ConfirmMoveFunds(){
            if(this.$refs.movefundsform.checkValidity()){
                this.beforeAPI();
                let req = {
                    adminfunds:this.AdminFundRequest,
                }
                var response = await this.callAPI(req,'movefunds')
                if(response.Successful){
                    this.responseMessage="Move Funds was successful"
                    this.$emit('refresh')
                
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            }
        },
        
    }
}
</script>

<style>

</style>





























