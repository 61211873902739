<template>
<div>
    <pencilbutton  @click="startUpdateInitialDue()" :disabled="disableAddFunds" />
    <!-- <linkbutton toplabel="Update Initial Due" @click="startUpdateInitialDue()" :disabled="disableAddFunds"/> -->
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="balform">
                <label class="titlelabel">Set Initial Account Due for {{AdminFundRequest.Name}}</label>
                <br/>
                
                <label class="mediumtext" >Old Initial Amount: {{AdminFundRequest.oldAmount | toCurrency}}</label>
                <formentry inputtype="number"  isrequired="True" label="Updated Initial Due $"  v-model="AdminFundRequest.newAmount"  />
                <formentry   label="Reason"  v-model="AdminFundRequest.Reason" isrequired="True" />
                <div>
                <label for="" class="required">{{requiredFieldLabel}}</label>
                </div>
                <smallbutton  @click="ConfirmInitialDue()" toplabel="Save"/>
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
            </form>
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import {mapFields} from 'vuex-map-fields'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import linkbutton from '../controls/linkbutton.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
import pencilbutton from '../controls/pencilbutton.vue'
export default {
    name:'updateinitialDue',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton,
        pencilbutton
    },
    props:{
        account:{
            type:Object,
            required:true
        }
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            
            responseMessage:'',
            pleaseWait:false,

            AdminFundRequest:{
                Name:'',
                fromAccount:0,
                toAccount:0,
                oldAmount:'',
                newAmount:'',
                Reason:''
            },
            
        }
    },
    computed:{
        ...mapFields([
            'disableAddFunds',

        ]),
        
    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        startUpdateInitialDue(){

      
            this.AdminFundRequest={
                Name:'',
                fromAccount:0,
                toAccount:0,
                oldAmount:'',
                newAmount:'',
                Reason:''
            }        
            this.AdminFundRequest.oldAmount=this.account.initialAmountDue.toString();
            this.AdminFundRequest.Name=this.account.Name;
            this.AdminFundRequest.fromAccount=this.account.ID;
            this.$refs.mymodal.openModal()
            this.responseMessage=''
        },
        async ConfirmInitialDue(){
            if(this.$refs.balform.checkValidity()){
                 this.beforeAPI();
                  let req = {
                    adminfunds:this.AdminFundRequest,
                }
                var response = await this.callAPI(req,'initialdue')

                if(response.Successful){
                    this.responseMessage="Update Inital Due was successful"
                    this.$emit('refresh')
                }else{
                this.message = response.Message
                }
                this.pleaseWait=false;
            }
         },
       
        
    }
}
</script>

<style>

</style>





























