<template>
 
      <div class="">
          <div class="horizFlexStretch">
            <label class="titlelabel">Institution</label>
          </div>
          <div >
            <institution />
          </div>
      </div>
  
</template>

<script>
import institution from './institution.vue'

export default {
    name:'admin',
    components:{
        institution
 
    },
    computed:{
        
    },
    mounted(){
        
    },
    data(){
        return{
           
        }
    },
    methods:{
        
        
    }
}
</script>

<style>

</style>