<template>
<div>
    <pencilbutton @click="startUpdatePreferred()"/>
    <!-- <linkbutton toplabel="Update Preferred" @click="startUpdatePreferred()"/> -->
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="mealplanform">
                <label class="titlelabel">Update Preferred Payment Method</label>
                <br/>
                <p class="subtitleBold">{{AccountPref.name}}</p>

                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Select Preferred</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="AccountPref.walletTokenId" required>
                        <option v-for="token in currentWallet.walletTokenList" :key="token.ID" :value="token.ID" >
                            {{token.Name}}
                        </option>
                    </select>
                </div>
                <smallbutton @click="savePreferred()" toplabel="Save"/>
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
      
            </form>
           
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
import linkbutton from '../controls/linkbutton.vue'
import pencilbutton from '../controls/pencilbutton.vue'
export default {
    name:'updatepreferredtoken',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton,
        pencilbutton
    },
    props:{
        account:{
            type:Object,
            required:true
        }
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            
            responseMessage:'',
            pleaseWait:false,

        
        }
    },
    computed:{
        ...mapFields([
            'currentWallet',

        ]),
        
    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        startUpdatePreferred(account){
            this.AccountPref={accountId:this.account.ID,
                name:this.account.Name,
                walletId:this.currentWallet.ID,
                walletTokenId:this.account.preferredWalletTokenId}
            this.$refs.mymodal.openModal()
        },
        async savePreferred(){

            this.beforeAPI();
            var response = await this.callAPI(this.AccountPref,'wallettokenpref')
           
            if(response.Successful){
                this.responseMessage="Update Preferred Payment Method was successful"
                this.$emit('refresh')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },  

    }
}
</script>

<style>

</style>





























