
import {HTTP} from './repository'
import {HTTPImage} from './repository'
export default{
    methods:{
        async callAPI_Image(req,method){
            try{
                console.log('call img api')
                var response = await HTTPImage.post(method,req)
                if(response.data.Successful==false){
                    return this.getFailureResponse(response.data.RequestException.Message)
                }
                return response.data
            }
            catch(e){
                console.log('!' + e)
                return this.getFailureResponse(e.message)
            }
        }, 


        AddTokenToAPI(token){
            
            HTTP.defaults.headers.post['token']=token
        },
        AddUserToAPI(user){
            console.log('add user to api ' + user.userId + ' ' + user.userKey)
            HTTP.defaults.headers.post['adminuser']=user.userId + '|' + user.userKey
        },
        getFailureResponse(message){
            return {
                'Successful':false,
                'Message':message
            }
        },
        async callAPI(req,method){
            try{
                var response = await HTTP.post(method,req)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                return response.data
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
        }, 
        async walletLogin(email, password){
            let req = {
                email:email,
                password:password
            }
            try{
                var response = await HTTP.post('walletloginuser',req)
                if(response.data.Successful==false){
                    console.log(response.data.RequestException.Message)
                    return this.getFailureResponse(response.data.RequestException.Message)
                }
                return  response.data;
            }
            catch(e){
                console.log("raw error " + e)
                return this.getFailureResponse(e.message)
            }
                
            
        },
        async RefreshWallet(walletID){
            let req = {
                wallet:{ID:walletID}
            }
            try{
                var response = await HTTP.post('walletrefresh',req)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                return  response.data;
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
        },
        
        async ChangePassword(email, oldpassword, newpassword,confirmpassword){
            if(newpassword!=confirmpassword){
                return this.getFailureResponse("Your passwords do not match. Please try again.")
            }
            let req = {
                email:email,
                oldpassword:oldpassword,
                newpassword:newpassword,
            }
            try{
                var response = await HTTP.post('walletchangepass',req)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                return  response.data;
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
                
            
        },
        async forgotMyPassword(email){
            if(this.username==""){
                
                return this.getFailureResponse("Please enter your email address and click forgot password")
              }
            let req = {
                email:email,
            }
            try{
                var response = await HTTP.post('walletforgotpass',req)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                return  response.data;
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
                
            
        },
        async addWalletToken(parentId){
            console.log('pid ' + parentId)
            let req = {
                locationId:'0',
                personId:parentId
            }
            try{
                var response = await HTTP.post('addwallettoken',req)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                return response.data
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
        },
        
        async saveNewAccount(parentId, newAccount){
            let req = {
                personId:parentId,
                walletToken:newAccount
            }
            try{
                var response = await HTTP.post('addwallettokenach',req)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                return response.data
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
        },

        //remove token
        async removeWalletToken(tokenID){
            let req = {
                walletTokenId:tokenID
            }
            try{
                var response = await HTTP.post('removewallettokenbyid',req)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                return response.data
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
        },

        //refill
        async saveAccountRefill(accountToUpdate){
            let req = {
                Account:accountToUpdate
            }
            try{
                var response = await HTTP.post('updaterefill',req)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                return response.data
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
        },
        //preferred
        async saveAccountPreferred(pref){

            try{
                var response = await HTTP.post('wallettokenpref',pref)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                return response.data
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
        },
        //add funds
        async getFundFees(account){
            let req = {
                initialAccountFundList:[account]
            }
            try{
                var response = await HTTP.post('getfee',req)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                return response.data
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
        },
        async saveAccountAddFunds(account){
            let req = {
                initialAccountFundList:[account]
            }
            try{
                var response = await HTTP.post('paymenttoaccountlistnodup',req)
                if(response.data.Successful==false){
                    console.log(response.data)
                    return this.getFailureResponse(response.data.RequestException.Message)
                }
                return response.data
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
        },

        //add student
        async AddNewStudent(NewPerson, walletId){
            
                this.NewPerson.FirstName=this.capitalize(this.NewPerson.FirstName)
                this.NewPerson.LastName=this.capitalize(this.NewPerson.LastName)
              let req = {
                  person:NewPerson,
                  walletId:walletId,
                  accountTag:window.ACCOUNT_TAG
              }
              try{
                var response = await HTTP.post('addpersonandaccount',req)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                return response.data
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
        },

        //admin
        async LookupWalletAccount(type, first, last, email, badge){
            console.log("lookuptype " + type)
            let req = {
                lookup:{
                    FirstName:first,
                    LastName:last,
                    EmailAddress:email,
                    BadgeNumber:badge,
                    SearchAccount:type=="2",
                    SearchWallet:type=="1"
                }
            }
            try{
                var response = await HTTP.post('walletlookup',req)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                return  response.data;
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
        },
        async RequestWalletRefund(refund){
           
            try{
              var response = await HTTP.post('walletrefundwithamount',refund)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
              return response.data
          }
          catch(e){
              console.log(e)
              return this.getFailureResponse(e.message)
          }
        },


        //get/update parent
        async GetPerson(personId){
            console.log(personId)
          let req = {
              person:{
                  ID:personId
              }
          }
          try{
            var response = await HTTP.post('getperson',req)
            if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
            return response.data
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
        },
        async SaveCurrentPerson(person){
            
          let req = {
              person:person,
          }
          try{
            var response = await HTTP.post('updateperson',req)
            if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
            return response.data
            }
            catch(e){
                console.log(e)
                return this.getFailureResponse(e.message)
            }
        },
        async SaveCurrentPerson_CheckBadge(person){
            
            let req = {
                person:person,
            }
            try{
              var response = await HTTP.post('updatepersoncheckbadge',req)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
              return response.data
              }
              catch(e){
                  console.log(e)
                  return this.getFailureResponse(e.message)
              }
          },
        async SaveNewPersonWallet(person){
            let req = {
                person:person,
            }
            try{
              var response = await HTTP.post('addpersonandwallet',req)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
              return response.data
              }
              catch(e){
                  console.log(e)
                  return this.getFailureResponse(e.message)
              }
        },
        async SaveMoveFunds(adminfunds){
            let req = {
                adminfunds:adminfunds,
            }
            try{
              var response = await HTTP.post('movefunds',req)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
              return response.data
              }
              catch(e){
                  console.log(e)
                  return this.getFailureResponse(e.message)
              }
        },
        async SaveInitialBalance(adminfunds){
            let req = {
                adminfunds:adminfunds,
            }
            try{
              var response = await HTTP.post('initialbalance',req)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                
              return response.data
              }
              catch(e){
                  console.log(e)
                  return this.getFailureResponse(e.message)
              }
        },
        
        async SaveNewTransaction(adminfunds){
            let req = {
                adminfunds:adminfunds,
            }
            try{
              var response = await HTTP.post('addtransaction',req)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                
              return response.data
              }
              catch(e){
                  console.log(e)
                  return this.getFailureResponse(e.message)
              }
        },

        //grant
        async LookupPersonByEmail(email, walletid){
            let req = {
                person:{EmailAddress:email,
                walletID:walletid}
            }
            try{
              var response = await HTTP.post('lookupbyemail',req)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                
              return response.data
              }
              catch(e){
                  console.log(e)
                  return this.getFailureResponse(e.message)
              }
        },
        async SaveGrantAccess(walletid, accountlist, walletlist){
            let req = {
                originalWalletID:walletid,
                accountList:accountlist,
                walletList:walletlist
            }
            try{
              var response = await HTTP.post('grantaccess',req)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                
              return response.data
              }
              catch(e){
                  console.log(e)
                  return this.getFailureResponse(e.message)
              }
        },
        async SaveMergeAccounts(accountlist, accountToRemain){
            let req = {
                accountList:accountlist,
                accountToRemain:accountToRemain
            }
            try{
              var response = await HTTP.post('mergeaccounts',req)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                
              return response.data
              }
              catch(e){
                  console.log(e)
                  return this.getFailureResponse(e.message)
              }
        },

        //meal plan
        async AccountTypes(){
            try{
                var response = await HTTP.post('accounttype',null)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                  
                return response.data
                }
                catch(e){
                    console.log(e)
                    return this.getFailureResponse(e.message)
                }
        },
        async AccountTypesMeal(){
            try{
                var response = await HTTP.post('accounttypemeal',null)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                  
                return response.data
                }
                catch(e){
                    console.log(e)
                    return this.getFailureResponse(e.message)
                }
        },
        async SaveMealPlan(mealplan){
            let req = mealplan;
            try{
                var response = await HTTP.post('addmealplan',req)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                  
                return response.data
                }
                catch(e){
                    console.log(e)
                    return this.getFailureResponse(e.message)
                }
        },
        
        async SaveChangedAccountType(mealplan){
            let req = mealplan;
            try{
                var response = await HTTP.post('updateaccounttype',req)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                  
                return response.data
                }
                catch(e){
                    console.log(e)
                    return this.getFailureResponse(e.message)
                }
        },
        async ReprocessPOSTickets(mealplan){
            let req = mealplan;
            try{
                var response = await HTTP.post('reprocesstickets',req)
                if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                  
                return response.data
                }
                catch(e){
                    console.log(e)
                    return this.getFailureResponse(e.message)
                }
        },
        //reports
        async GetReportList(){
            let req = {
                filterByName:'',
            }
            try{
              var response = await HTTP.post('reportlist',req)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                console.log(response.data)
              return response.data
              }
              catch(e){
                  console.log(e)
                  return this.getFailureResponse(e.message)
              }
        },
        async GenerateReport(id, fromDate, toDate,min,max){
            let req = {
                reportID:id,
                fromDate:fromDate,
                toDate:toDate,
                minNumber:min,
                maxNumber:max
            }
            try{
              var response = await HTTP.post('generatereport',req)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                
              return response.data
              }
              catch(e){
                  console.log(e)
                  return this.getFailureResponse(e.message)
              }
        },
        async GetImagePath(){
            let req = {
               
            }
            try{
              var response = await HTTP.post('reportimagepath',req)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                console.log(response.data)
              return response.data
              }
              catch(e){
                  console.log(e)
                  return this.getFailureResponse(e.message)
              }
            
        },
        async SaveWalletNote(note, walletId){
            let req = {
                walletId:walletId,
                note:note
            }
            try{
              var response = await HTTP.post('addwalletnote',req)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                console.log(response.data)
              return response.data
              }
              catch(e){
                  console.log(e)
                  return this.getFailureResponse(e.message)
              }

        },
        async SaveTransactionNote(note, accountId, transactionId){
            let req = {
                accountId:accountId,
                accountTransactionId:transactionId,
                note:note
            }
            try{
              var response = await HTTP.post('addtransactionnote',req)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                console.log(response.data)
              return response.data
              }
              catch(e){
                  console.log(e)
                  return this.getFailureResponse(e.message)
              }

        },
        async SaveAccountNote(note, accountId){
            let req = {
                accountId:accountId,
                note:note
            }
            try{
              var response = await HTTP.post('addaccountnote',req)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                console.log(response.data)
              return response.data
              }
              catch(e){
                  console.log(e)
                  return this.getFailureResponse(e.message)
              }

        },
        
        async SaveAsAdmin(wallet){
            let req = {
                wallet:wallet
            }
            try{
              var response = await HTTP.post('wallettoadmin',req)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                console.log(response.data)
              return response.data
              }
              catch(e){
                  console.log(e)
                  return this.getFailureResponse(e.message)
              }

        },

        async GetReceipt(transactionId){
            let req = {
                transactionId:transactionId
            }
            try{
              var response = await HTTP.post('receipt',req)
              if(response.data.Successful==false){return this.getFailureResponse(response.data.RequestException.Message)}
                console.log(response.data)
              return response.data
              }
              catch(e){
                  console.log(e)
                  return this.getFailureResponse(e.message)
              }

        }
    },
    
}