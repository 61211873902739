<template>
    <footer>
        <a class="footerlink" :href="footerLogoURL" target="_blank" tabindex="-1">
          <img alt="school logo" :src="logoPath"  class="footerlogo" v-if="logoPath!=''" >
        </a>
        
        <label for="">© {{footerCopyrightLabel}}</label>
        <a class="button"  @click="$refs.disclaimerModal.openModal()">Payment Disclosure</a>
        <label for="">{{privacyLinkText}}</label>
        
        <a class="footerlink" :href="footerPrivacyURL" target="_blank" tabindex="-1">{{footerPrivacyURL}}</a>



        <!-- DISCLAIMER VIEWONLY -->
      <modal ref="disclaimerModal">
        <template v-slot:header>
          <div class="horizFlex">
            <p class="subtitleBold">Payment Disclosure</p>
            <img alt="cardlogo" :src="cardLogoPath"  class="logocard">
          </div>
          
        </template>

        <template v-slot:body>
          <p class="subtitle">By clicking I Accept, you are providing consent to School-Payments to securely store your payment method information to be used for 
            the funding of your student school account(s). All notifications regarding this account will be sent by email from School-Payments, 
            including any changes to terms and conditions. Your credential will only be used for the student school account(s) and only for the 
            initial and refill amounts you specify. This consent will automatically expire 12 months from today: {{cardStorageDate}}. 
            You will be notified to renew this information at that time. All transactions will be funded in US Dollars. You may cancel at anytime by contacting the school at {{locationEmail}}. 
            Refunds will be issued at the end of the 12 month period unless you choose to roll the funds over when you renew your credential. 
            Your school's phsyical address is: {{locationAddress}}. This agreement will remain on file for the duration of the 12 month period 
            and a copy can be provided to you at any time by requesting a copy by email at {{locationEmail}}. Please call the following phone number with questions: {{locationPhone}}</p>
        </template>
        <template v-slot:body2>
          <p class="subtitle"> 
            You will be charged a service charge of 3.99% and 10 cents for each transaction using a bankcard and $1.49 
            for any transaction using a checking account. NOTE: THESE SERVICE CHARGE TRANSACTIONS WILL BE BILLED SEPARATELY 
            FROM THE ORIGINAL TRANSACTION AND MAY BE PROCESSED UP TO 14 DAYS AFTER THE ORIGINAL TRANSACTION HAS FUNDED SUCCESSFULLY. 
            THIS SERVICE CHARGE WILL APPEAR AS "SCHOOL PAYMENTS" ON YOUR CREDIT CARD OR BANK STATEMENT.</p>
        </template>
       
        <template v-slot:footer>
          <div>
            <button class="tallButton buttonprimary" @click="$refs.disclaimerModal.closeModal()">Close</button>
          </div>
        </template>
      </modal>
    </footer>
</template>

<script>
import linkbutton from './controls/linkbutton.vue';
import modal from './controls/modal.vue'
export default {
  components: { linkbutton, modal },
    name: "spfooter",
    data() {
        return {
            

           

            /* logoFilePath:require('@/assets/PBschoolpay.jpg'),
            privacyLinkText: 'For our privacy policy please visit', */
            poweredByText:'Powered by School-Payments',
            cardLogoPath:require('@/assets/card.jpg'),
        };
    },
    computed:{
        logoPath(){
           // return this.logoFilePath
            if( window.FOOTER_LOGO!=undefined){
                return './../' + window.FOOTER_LOGO
            }else{ return require('@/assets/PBschoolpay.jpg')}
        },
        privacyLinkText(){
          if(window.FOOTER_PRIVACYTEXT!=undefined){
            return  window.FOOTER_PRIVACYTEXT
          }else{ return 'For our privacy policy please visit'}
        },
        footerLogoURL(){
          if(window.FOOTER_LOGOURL!=undefined){
            return window.FOOTER_LOGOURL
          }else{return 'https://www.School-Payments.com'}
        },
        footerCopyrightLabel(){
          if(window.FOOTER_COPYRIGHTLABEL!=undefined){
            return window.FOOTER_COPYRIGHTLABEL
          }else{return '2021 DD New, LLC dba School-Payments'}
        },
        footerPrivacyURL(){
          if(window.FOOTER_PRIVACYURL!=undefined){
            return window.FOOTER_PRIVACYURL
          }else{return 'https://www.School-Payments.com/Privacy'}
        }
    }
}
</script>

<style>
footer{
    grid-area:footer;
    padding:10px;
    display:flex;
    flex-direction:column;
    text-align: center;
    font-size: small;
    color:var(--text-color);
}
.footerlink:link{
    color:var(--text-color);
}
.footerlink:visited{
    color:var(--text-color);
}
.footerlink:hover{
    color:var(--button-color);
}
.footerlogo{
  height:65px;
}
.footertextlight{
  color:var(--light-text-color);
  width:70%;
}
.darktext{
    color:var(--text-color);
    height:40px;
}
.button{
    text-decoration: underline;
    min-width: 75px;
    height:40px;
    padding:0px;
    font-size: small;
    margin-right:5px;

}
.subtitleBold{
  font-size:large;
  margin:20px;
  font-weight: bold;
}
</style>