<template>
  <div class="vertFlex ">
    <!-- filter -->
    <div class="borderedfilter vertFlexLeft" >
        <label class="titlelabel">{{title}}</label>
        

        
        <!-- main -->
        <transfilter ref="transfilter" @gofilter="goFilter" :selectedWalletId="walletId" :accountList="accountList"/>
         <div v-if="pleaseWait">
            <label class="pleasewaitlabel">{{pleaseWaitText}}</label>
        </div>
        <div class="centereddiv">
            <label class="messagetext" >{{responseMessage}}</label>
        </div>
    </div>


    <!--summary -->
     <div  v-if="hasTransactions">
        <transsummary  :dataList="typeSummary" />
    </div> 

    <!-- transactions -->
    <div class="">
        <itemwithdetaillist :transactionList="transactionList" v-if="hasTransactions" @refresh="refresh" @gotowallet="gotowallet"/>
    </div>
    <div class="leftdiv">
        <label for="" class="internal">{{lastFilterString}}</label>
    </div>
    

  </div>
</template>

<script>
import smallbutton from '../controls/button.vue'
import linkbutton from '../controls/linkbutton.vue'
import transfilter from './filter.vue'
import summarybox from '../controls/summarybox.vue'
import labeledlist from '../controls/labeledlist.vue'
import itemwithdetaillist from './itemwithdetaillist.vue'
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import transsummary from './summary.vue'
import {mapFields} from 'vuex-map-fields'
export default {
    name:'transactionexplorer',
    components:{
      smallbutton,
      linkbutton,
      transfilter,
      summarybox,
      labeledlist,
      itemwithdetaillist,
      transsummary
    },
    props:{
        walletId:{
            type:Number,
            default:-1
        },
        accountList:{
            type:Array
        },
        walletName:{
            type:String
        }
    },
    mixins:[datamethods, format],
    data(){
        return{
            pleaseWaitText:'Please wait...',
            pleaseWait:false,
            message:'',

        }
    },
    
    computed:{
        ...mapFields([
            'transactionList',
            'typeSummary',
            'filter',
            'responseMessage'

        ]),
        lastFilterString(){

            var str='Internal: '
            str=str + 'walletid: ' + this.filter.walletId + ' accountid: ' + this.filter.accountId 
            str=str + ' typeid: ' + this.filter.accountTransactionTypeId + ' tokentypeid: '  + this.filter.walletTokenTypeId
            str=str + ' startdate: ' + this.filter.startDate + ' toDate: ' + this.filter.toDate
            str=str + ' min: ' + this.filter.min + ' max: ' + this.filter.max + ' extid: ' + this.filter.externalTransactionId
            str = str + ' inclpend: ' + this.filter.includePending
            str = str + ' inclvoid: ' + this.filter.includeVoided
            str = str + ' inclfail: ' + this.filter.includeFailed
            str = str + ' inclzero: ' + this.filter.includeZeroDollar

            return str

        },
        hasTransactions(){
            return this.transactionList.length>0
        },
        title(){
            if(this.walletId==-1){
                return "Explore Transactions for All Wallets"
            }else{return "Explore Transactions for " + this.walletName}
        }
    },
    methods:{
        clearFilter(){
            this.$refs.transfilter.clearFilter();
        },
        beforeAPI(){
            this.pleaseWait=true
            this.message=''
        },
        
        async goFilter(){
            
            //setup request
            let req = {
                lookup:this.filter
            }
            //call api
            this.beforeAPI();
            var response = await this.callAPI(req,'transactions')
            //handle response
            if(response.Successful){
                this.transactionList=response.transactionList
                this.typeSummary=response.typeSummaryList
               // this.transSummary=response.transSummaryList
               if(this.transactionList.length==0){this.message="No Transactions Found"}
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
            
        },
        refresh(){
            console.log('in refrsh expl')
            this.goFilter(this.lastFilter)
        },
        gotowallet(walletId){
            this.$emit('gotowallet', walletId)
        }
    
    }
}
</script>

<style>

.borderedfilter{

    background-color: var(--frame-color);
    border-radius:10px;
    margin:10px 0px 10px 0px;
    padding:20px;
   

}

.vertFlexLeft{

  display:flex;
  flex-direction: column;
  align-items:left;
  text-align: left;

}
.flexwrap{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
}



.internal{
    font-size:small;
    color:white;
}

.centereddiv{
    text-align: center;
}
.leftdiv{
    text-align: left;
}

</style>

