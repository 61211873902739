<template>
  <div class="outerDiv" :style="configStyle">
        <navheader/>

        <div :class="mainDivClass">
            <loginuser ref="loginuser" v-if="!loggedIn"/>
            <div v-if="loggedIn">
                <explorer v-if="currentNav=='explorer'"/>
                <walletreport @closereport="cancel()" v-if="currentNav=='report'"/>
                <lookupviewwallet v-if="currentNav=='wallet'" ref="walletView"/>
                <admin v-if="currentNav=='admin'"/>
                
            </div>
        </div>

        <spfooter/>  
        
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import spfooter from './footer.vue'
import navheader from './navheader.vue'
import loginuser from './loginuser.vue'
import explorer from './explorer/explorer.vue'
import walletreport from './report/report.vue'
import lookupviewwallet from './walletlookup/lookupviewwallet.vue'
import admin from './admin/admin.vue'
import style from '../style.js'
export default {
    components:{
        spfooter,
        navheader,
        loginuser,
        explorer,
        walletreport,
        lookupviewwallet,
        admin,
    
    },
    mixins:[style],
    computed:{
        ...mapFields([
            'loggedIn',
            'currentNav',
            'username',
            'oldpassword',
            'loggedInUser.isAdmin',
            'logoOverride',
            'configOverride',
            'showHeader',
            'personImageBasePath'
        

        ]),
        mainDivClass(){
            if(this.isAdmin){return "mainDivAdmin"}
            else{return "mainDivFullWidth"}
        }
    },
    methods:{
        getImageBasePath(){
            
            this.personImageBasePath = window.VUE_CONFIG_API + 'personimage/'
            console.log('pimg path ' + this.personImageBasePath)
        },

        receiveMessage(event){
        
        var msg =""
        try{
            msg=JSON.parse(event.data)
        }catch(e){
            
        }
        var name = msg.to
        if(name !=undefined){
          
          console.log(name)
          console.log('FA msg' + event.data)

          switch (msg.action) {
            case 'refresh':
                this.$refs.walletView.RefreshCurrentWallet()
                break;
            case 'goto':
                this.$refs.walletView.getWallet(msg.id)
                this.currentNav=='wallet'
                break;
            default:
                break;
          }
          
        }
        
      }
    },
    mounted(){
         window.addEventListener('message', this.receiveMessage)

        let urlParams = new URLSearchParams(window.location.search)
        let tmp = urlParams.get('email')
        if(tmp!=null){
            this.username = tmp;
        }

        let tmp2 = urlParams.get('temp')
        if(tmp2!=null){
            this.oldpassword = tmp2;
            this.$nextTick(()=>{
            //this.changepass()
            this.$refs.loginuser.changepassword();
            });
        }

        let tmp3 = urlParams.get('logo')
        if(tmp3!=null){ this.logoOverride = tmp3;}

        let tmp4 = urlParams.get('config')
        if(tmp4!=null){this.configOverride=tmp4 }

        

        console.log("inmount")
        window.onbeforeunload= function(event){
            event.preventDefault()
        }

        tmp = urlParams.get('header')
        if(tmp!=null){this.showHeader=tmp=='true'; }

        tmp = urlParams.get('token')
        if(tmp!=null){
            console.log('sso!')
            this.$refs.loginuser.post_login(tmp)
         }

         this.setColors();
         this.getImageBasePath();
    }
}
</script>

<style lang="scss">
@import "@/assets/stylebase.scss";
:root{
    /* --button-color:#035891!important;
    --highlight-color:#96BAE7!important;
    --border-color:#58585A!important;
    
    --text-color:rgb(58, 54, 54)!important;
    --light-text-color:white!important; */
    --required-color:red!important;
    --security-color:green!important;
    --pay-color:#489642!important;
    --meal-color:rgb(144, 66, 144)!important;
}




body{
  height:95vh; 
  height: -webkit-fill-available;
  width:100vw;
  padding:0px;
  margin:0px;
  font-size:medium;
  color:var(--text-color);
}
.outerDiv{
  margin:0px;
  padding:0px;
    height:95vh;
    display:grid;
    grid-template-columns:1fr 4fr 1fr;
    grid-template-rows:90px auto 110px;
    grid-template-areas: 
        "header header header"
        "space center space2"
        "footer footer footer";
}
.mainDivFullWidth{

    grid-row:2;
    grid-column-start:1;
    grid-column-end: span 3;
    background-color:var(--background-color);
    padding:20px;
}
.mainDivAdmin{
    grid-row:2;
    grid-column-start:1;
    grid-column-end: span 3;
    background-color:var(--background-color);
    padding:20px;
     
}

.bordered{
    background-color: white;
    border-radius:10px;
    margin:10px 0px 10px 0px;
    padding:20px;
}
.responsemessage{
    font-size:large;
    color:red;
}

.horizFlex{
  display:flex;
  flex-direction: row;
  margin:0px;
}
.vertFlex{
  display:flex;
  flex-direction: column;
  margin:0px;
  
}
.horizFlexStretch{
  display:flex;
  padding:0px;
  flex-direction: row;
  margin:0px 0px 0px 10px ;
  justify-content:space-between;
}
.rightjustified{
    text-align: right;
}
.leftjustified{
    text-align: left;
}
.subtitle{
    font-size:large;
    margin:20px;
}
.subtitleBold{
  font-size:large;
  margin:20px;
  font-weight: bold;
}
button:hover{
    background-color:var(--highlight-button-color);
    cursor:pointer;
}
button:disabled{
  background-color:gray;
}
.bold{
    font-weight: bold;
}
.left5{
    margin-left:5px;
}
.left10{
    margin-left:10px;
}
.right10{
    margin-right:10px;
}
.bottom10{
    margin-bottom:10px;
}
.messagetext{
    color:black;
    font-size:medium;

}
.pleasewaitlabel{
    color:orangered;
    font-size:large;
}
.titlelabel{
 
    font-size:x-large;
}
textarea{
    font:inherit;
}
</style>