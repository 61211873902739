<template>
<div>
    <p class="responsemessage" v-if="disableStartAddFunds">Please add a card or bank account above before you {{titleText}}</p>
    <confirm :body="getConfirmText" :title="titleText" @yes="startAddFunds" :showButton="trueParam" :disabled="disableStartAddFunds" buttonclass="button buttonpay"/>
    
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            

                <div v-if="!showConfirm">
                    <form ref="addfundsform">
                        <label class="titlelabel">{{titleText}} to Account</label>
                        <br/>
                        <p class="subtitleBold">{{FundRequest.Name}}</p>
                        <div class="entry">
                            <div class="entryTextDiv">
                            <label class="entryLabel" for="">Select Payment Method</label>
                            </div>
                            <select name="" id="" class='entrySelect' v-model="FundRequest.TokenID" required>
                                <option v-for="token in currentWallet.walletTokenList" :key="token.ID" :value="token.ID" >
                                    {{token.Name}}
                                </option>
                            </select>
                        </div>
                        <formentry inputtype="number" isrequired="True" label="Amount to Add"  v-model="FundRequest.Amount" />
            
                        <smallbutton  @click="GetTax()" toplabel="Save"/>
                        <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Cancel"/>
                        <div v-if="!showConfirm">
                            <label class="responsemessage">{{responseMessage}}</label>
                        </div>
                    </form>
                </div>
                <div v-if="showConfirm">
                    <form>
                        <div class="vertFlex">
                            <label class="subtitleBold">Confirm {{titleText}} to Account</label>
                            <br/>
                            <label for="" class="subtitleBold">
                                {{FundRequest.Name}} 
                            </label>
                            <label class="listtextbold">
                                Amount to Add: {{FundRequest.Amount | toCurrency}}
                            </label>
                            <label class="listtextbold" v-if="FundRequest.TaxAmount!='0'">
                                Tax: {{FundRequest.TaxAmount | toCurrency}}
                            </label>
                            <label class="listtextbold" v-if="FundRequest.FeeAmount!='0'">
                                Service Fee: {{FundRequest.FeeAmount | toCurrency}}
                            </label>
                            <label class="listtextbold" >
                                Total: {{TotalAmount | toCurrency}}
                            </label>
                         </div>
                         <smallbutton  @click="submitFunds()" toplabel="Confirm" :disabled="disableSubmit"/>
                        <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Cancel"/>
                        <div>
                            <label class="responsemessage">{{responseMessage}}</label>
                        </div>
                    </form>
                </div>
                
            
            
        </template>
      </actionmodal>

      
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import {mapFields} from 'vuex-map-fields'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import linkbutton from '../controls/linkbutton.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
import confirm from './confirm.vue'
export default {
    name:'addfunds',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton,
        confirm
    },
    props:{
        account:{
            type:Object,
            required:true
        }
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            trueParam:true,
            responseMessage:'',
            pleaseWait:false,

            FundRequest:{
                ID:0,
                Name:'',
                TokenID:0,
                Amount:'',
                FeeAmount:0,
                TaxAmount:0
            },
            showConfirm:false,
            disableSubmit:false
        }
    },
    computed:{
        TotalAmount(){
            return this.FundRequest.FeeAmount + this.FundRequest.TaxAmount + this.FundRequest.Amount
        },
        ...mapFields([
            'disableAddFunds',
            'currentWallet'

        ]),
        titleText(){
            if(window.ADD_FUNDS_TEXT==undefined){
                return "Add Funds"
            }else{
                return window.ADD_FUNDS_TEXT
            }
        },
        getConfirmText(){
            return "You are about to " + this.titleText + " using the wallet account of " + this.currentWallet.Name + ". Do you want to continue?"
        },
        disableStartAddFunds(){
            if(this.disableAddFunds){return true}
            if(this.currentWallet.walletTokenList.length==0){return true}
            return false
        },
        LimitToDue(){
            if(window.LIMIT_PAY_TO_DUE==undefined){
                return true
            }else{
                return window.LIMIT_PAY_TO_DUE
            }
        },
        
    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        
        startAddFunds(){
            this.responseMessage=''
            this.FundRequest.ID=this.account.ID
            this.FundRequest.Name=this.account.Name

            this.FundRequest.TokenID=0
            this.FundRequest.Amount=''
            this.FundRequest.FeeAmount=0
            this.FundRequest.TaxAmount=0
            


            this.showConfirm=false
            this.disableSubmit=false
            this.$refs.mymodal.openModal()
            
        },

    async calcFees(){
        if(this.$refs.addfundsform.checkValidity()){
            //validate amt
            if(this.LimitToDue){
                if(Number(this.FundRequest.Amount)> Number(this.account.amountDue)){
                    console.log('toomuch')
                    this.responseMessage="Please enter an amount less than or equal to the amount due."
                    return
                }
            }


            this.beforeAPI();
            let req = {
                initialAccountFundList:[this.FundRequest]
            }
            var response = await this.callAPI(req,'getfee')
            
            
            if(response.Successful){
                this.showConfirm=true
                this.FundRequest=response.initialAccountFundList[0]
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        }
    },
    async GetTax(){
 
        //create req
        let req = {
            Product:{
                id:-1,
                price:this.FundRequest.Amount
            }
            
        }
        //call
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'gettax')
        //handle response
        if(response.Successful){
            var taxamount = response.responseDouble
            console.log(taxamount)
            //add the tax to the record  
            this.FundRequest.TaxAmount=taxamount
            
        
            this.calcFees();//now we can add the fees!
        }else{
            this.message = response.Message
        }
        this.pleaseWait=false;
        },
    async submitFunds(){
      

      console.log("submit funds")
      this.disableSubmit=true;
      this.beforeAPI();
      let req = {
            initialAccountFundList:[this.FundRequest]
        }
      var response = await this.callAPI(req,'paymenttoaccountlistnodup')

      if(response.Successful){
       
        this.$emit('refresh')
        this.responseMessage="Submit Funds Successful"
        this.$refs.mymodal.closeModal()
      }else{
        this.responseMessage = response.Message
        this.disableSubmit=false;
      }
      this.pleaseWait=false;
      
    },
    cancelConfirm(){
      this.showConfirm=false

    },
    clearFundRequest(){
      this.FundRequest={
        ID:0,
        Name:'',
        TokenID:0,
        Amount:'',
        FeeAmount:0
      }
    },
    
       
        
    }
}
</script>

<style>

</style>





























