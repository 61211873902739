<template>
<div>
    <pencilbutton  @click="startUpdate()"/>
    <!-- <linkbutton toplabel="Update" @click="startUpdate()"/> -->
    <!-- MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form id="updateform" ref="updateform" @submit.prevent>
                <label class="titlelabel">Update {{institution.name}}</label>
                <formentry label="Address 1"  v-model="institution.AddressLine1" />
                <formentry label="Address 2"  v-model="institution.AddressLine2" />
                <formentry label="City"  v-model="institution.City" />
                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">State</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="institution.StateID" >
                        <option v-for="s in stateList" :key="s.id" :value="s.id" >
                            {{s.name}}
                        </option>
                    </select>
                </div>
                <formentry label="Postal Code"  v-model="institution.ZipCode" />
                <formentry label="Contact Name"  v-model="institution.ContactName" />
                <formentry label="Contact Email" inputtype="email"  v-model="institution.ContactEmailAddress" />
                <formentry inputtype="tel" label="Contact Phone"  @blur="leavePhone"  v-model="institution.ContactPhoneNumber" />
                <formentry label="Contact From Name"  v-model="institution.ContactFromName" />
                <formentry label="Deactivate Below Balance $"  inputtype="Number" v-model="institution.DeactivateBelowBalance" />
                <formentry label="Minimum Balance Alert $"  inputtype="Number" v-model="institution.MinimumBalance_Alert" />
                <formentry label="Minimum Balance Suspend $"  inputtype="Number" v-model="institution.MinimumBalance_Suspend" />
                <formentry label="Relation To Acct. (Ex. Parent)"  v-model="institution.RelationToAccount_Default" />
                <formentry label="Relation To Wallet (Ex. Student)"  v-model="institution.RelationToWallet_Default" />
                
                <div class="entry">
                    <div class="entryTextDiv">
                        <input ref="defaultRadio" type="radio" v-bind:checked="!customEmailFooter" @change="useDefault()">
                        <label class="entryLabel" for="">Default Email Footer</label>
                    </div>
                    
                    <label class="entryLabel" for="">{{institution.EmailFooter}}</label>
                </div>
                <div class="entry">
                    <div class="entryTextDiv">
                        <input type="radio" v-bind:checked="customEmailFooter">
                        <label class="entryLabel" for="">Custom Email Footer</label>
                    </div>
                    
                    <input class="entryText" v-model="institution.EmailFooter_Override"/>
                </div>
            

       
                <smallbutton  @click="save()" toplabel="Save" />
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Cancel"/>
                <div>
                    <label class="responsemessage">{{message}}</label>
                </div>
            </form>
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
import linkbutton from '../controls/linkbutton.vue'
import formentryselect from '../controls/entry-select.vue'
import pencilbutton from '../controls/pencilbutton.vue'
export default {
    name:'updateinstituion',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton,
        formentryselect,
        pencilbutton
    },
    props:{
        institution:{
            type:Object,
            required:true
        },
    },
    mixins:[datamethods,format],
    data(){
        return{
            responseMessage:'',
            stateList:[],
            
        }
    },
    computed:{
        customEmailFooter(){
            if(this.institution.EmailFooter_Override === null){
            
                return false;
            }else{
                return true
            }
        }
    },
    
    methods:{
        useDefault(){
            if(this.$refs.defaultRadio.checked){
                this.institution.EmailFooter_Override=null;
            }
        },
        async startUpdate(){
            this.message = ''
            this.$refs.mymodal.openModal()

        },
        leavePhone(){
            this.institution.ContactPhoneNumber=this.$options.filters.formatPhone(this.institution.ContactPhoneNumber);
        },
        async save(){
            if(this.$refs.updateform.checkValidity()==false){return}

        
            let req = {
                institution:this.institution,
            }
            var response = await this.callAPI(req,'saveinstitution')
            if(response.Successful){
                this.$refs.mymodal.closeModal()
                console.log(response)
                this.$emit('refresh')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        async getStates(){
            var response = await this.callAPI(null,'states')
            if(response.Successful){
                this.stateList = response.stateList
            }else{
                this.responseMessage = response.Message
            }

        }
       
    },
    mounted(){
        this.getStates()
        
    },
}
</script>

<style>
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
.entryText{
    margin:5px;
    width:95%;
    font-size:inherit;
}
</style>





























