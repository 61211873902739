<template>
    <div>
        <smallbutton toplabel="Refund" @click="startRequestRefund()" v-if="showButton" class="button buttonpay"/>
        <linkbutton toplabel="Refund" @click="startRequestRefund()" v-if="!showButton"/>
        <!--  MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <form ref="balform">
                    <label class="titlelabel">Refund Card {{refundAmt | toCurrency}}</label>
                    <br/>
                    <label class="">{{transaction.message}}</label>
                    <br/>
                    <formentry inputtype="number"  isrequired="True" label="Refund Amount $"  v-model="refundAmt"  />
                    <formentry   label="Reason"  v-model="notes" />
    
                    <smallbutton  @click="refund()" toplabel="Refund" :disabled="disableRefund"/>
                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                </form>
                
                
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import datamethods from '../../datamethods.js'
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import actionmodal from './actionmodal.vue'
    import formentry from '../controls/entry.vue'
    export default {
        name:'refundcard_transactionlist',
        components:{
            smallbutton,
            actionmodal,
            formentry,
            linkbutton
        },
        props:{
            transaction:{
                type:Object,
            },
            showButton:{
                type:Boolean,
                default:false
            }
            
        },
        mixins:[datamethods,format],
        data(){
            return{
                trueParam:true,
                responseMessage:'',
                pleaseWait:false,
                notes:null,
                startingAmt:0,
                refundAmt:0
                
            }
        },
        computed:{
            ...mapFields([
                'currentWallet'
    
            ]),
            
        },
        methods:{
            beforeAPI(){
                this.pleaseWait=true
                this.responseMessage=''
            },
            startRequestRefund(){
                this.disableRefund=false
                this.responseMessage=''
                this.notes=null
                this.startingAmt=this.transaction.amount
                this.refundAmt=this.transaction.amount
                this.$refs.mymodal.openModal()
            },
            async refund(){
                var amtreq = parseFloat(this.refundAmt)
                var amtbal = parseFloat(this.startingAmt)
                if(amtreq>amtbal){
                    this.responseMessage="Please enter a refund amount less than or equal to the transaction amount"
                    return;
                }
                console.log('in refund')
                
                var req={
                    at:{
                        ID:this.transaction.transactionId,
                        IsActive:true,
                        TransactionTypeName:this.transaction.TransactionTypeName,
                        accountId:this.transaction.accountId,
                        details:this.transaction.transactionDetails,
                        walletTokenId:this.transaction.walletTokenId,
                        amount:this.refundAmt,
                        originalAmount:this.startingAmt
                    },
                    notes:this.notes
                }
                this.beforeAPI();
                var response = await this.callAPI(req,'refundtransaction')
                
                if(response.Successful){
                    /* this.responseMessage="Refund was successful"
                    this.$emit('refresh')
                    this.disableRefund=true */
    
                    this.$emit('refresh')
                    this.responseMessage="Refund Successful"
                    this.$refs.mymodal.closeModal()
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            },
            
           
            
        }
    }
    </script>
    
    <style>
    
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    