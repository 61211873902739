<template>
<div>
    <smallbutton toplabel="Add Wallet" @click="AddParent()"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form>
                <label class="titlelabel">Add Parent</label>
                <formentry label="Parent/Guardian First Name" isrequired='True' v-model="parent.FirstName" />
                <formentry label="Parent/Guardian Last Name" isrequired='True' v-model="parent.LastName"  />
                <formentry  inputtype="tel"  label="Phone Number" @blur="leavePhone"  v-model="parent.PhoneNumber1" />
                <formentry  inputtype="email"  label="Email Address" isrequired='True' v-model="parent.EmailAddress" />
                <formentry  inputtype="password"  label="Password" isrequired='True' v-model="parent.password" />
                <formentry  inputtype="password"  label="Confirm Password" isrequired='True' v-model="parent.confirmPassword" />
                <div class="vertFlex ">
                    <label for="" class="mediumtext">How do you want to receive updates?</label>
                    <label for="" class="mediumtext">(choose at least one)</label>
                
                    <div class="horizFlex topMargin centered">
                        <input class="entryCheckbox" type="checkbox" v-model="parent.optInForText" >
                        <label for="" :class="checkClass(parent.optInForText)">Via mobile phone text message </label>
                    </div>
                    <label for="" class="mediumtext">(Standard messaging and data rates may apply.)</label>
                    <div class="horizFlex centered">
                        <input class="entryCheckbox" type="checkbox" v-model="parent.optInForEmail" >
                        <label for="" :class="checkClass(parent.optInForEmail)">Via email</label>
                    </div>
                </div>
    
                <smallbutton  @click="saveNewParent()" toplabel="Save" />
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Cancel"/>
                <div>
                    <label class="responsemessage">{{message}}</label>
               </div>
          </form>
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
export default {
    name:'newwallet',
    components:{
        smallbutton,
        actionmodal,
        formentry
    },
    props:{

        
    },
    mixins:[datamethods,format],
    data(){
        return{
            parent:{},
            message:''
        }
    },
    methods:{
        async AddParent(){
            this.parent={
                FirstName:'',
                LastName:'',
                PhoneNumber1:'',
                EmailAddress:'',
                optInForEmail:false,
                optInForText:false,
                password:'',
                confirmPassword:''
            }
            this.$refs.mymodal.openModal()
        },
        checkClass(selected){
            if(selected){
                return "entryCheckLabelbold"
            }else{
                return "entryCheckLabel"
            }
        },
        async saveNewParent(){
            if(!this.parent.optInForText && !this.parent.optInForEmail){
                this.message="Please select either text or email updates"
                return
            }
            if(this.parent.optInForText && this.parent.PhoneNumber1==''){
                this.message="Please enter a phone number for text updates"
                return
            }

            let req = {
                person:this.parent,
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'addpersonandwallet')
            if(response.Successful){
                this.$refs.mymodal.closeModal()
                console.log(response)
                this.$emit('refresh',response.walletId)
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        leavePhone(){

            this.parent.PhoneNumber1=this.$options.filters.formatPhone(this.parent.PhoneNumber1);
            
            
        },
    }
}
</script>

<style>
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
</style>





























