import { render, staticRenderFns } from "./pencilplusbutton.vue?vue&type=template&id=24cda742&"
import script from "./pencilplusbutton.vue?vue&type=script&lang=js&"
export * from "./pencilplusbutton.vue?vue&type=script&lang=js&"
import style0 from "./pencilplusbutton.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports