import Vue from 'vue'
import Vuex from 'vuex'

import {getField, updateField} from 'vuex-map-fields'

Vue.use(Vuex)
export const store = new Vuex.Store({
    state:{
        //general
        currentNav:'wallet',
        username:'',
        oldpassword:'',
        loggedInUser:{
            name:'User',
            isAdmin:false,
            walletId:0
        },
        loggedIn:false,
        showHeader:true,
        securityAppName:'FundingAdmin',
        securityAppItems:[],

        personImageBasePath:null,

        lastRefreshDateTime_Wallet:'',
        lastRefreshDateTime_Explorer:'',

        //wallet
        disableAddFunds:true,
        showAddFunds:true,

        walletFilter:{
            lookupType:'1',
            lookupFirst:'',
            lookupLast:'',
            lookupEmail:'',
            lookupBadge:'',
            showInactiveWallets:false,
        },
        lookupresults:[],
        currentWallet:{},

        //explorer
        filter:{
            walletId:-1,
            accountId:-1,
            walletTokenTypeId:-1,
            walletTokenId:-1,
            accountTransactionTypeId:-1,
            startDate:null,
            toDate:null,
            min:null,
            max:null,
            externalTransactionId:null,
            includePending:true,
            includeFunded:true,
            includeFailed:true,
            includeVoided:true,
            includeZeroDollar:true,
            restrictToLocation:-1
        },
        walletTokenTypeList:[],
        locationList:[],
        accountTransactionTypeList:[],

        transactionList:[],
        typeSummary:[],
        selectedTrans:{},
        lastFilterWalletId:-1,

        logoOverride:'',
        imageBasePath:'',
        configOverride:'',
    },
    mutations:{
        updateField,
        resetbeforeapi(state){
            state.pleaseWait=true;
            state.responseMessage=''
        },
        resetdate_wallet(state){
            var currentdate = new Date().toLocaleString('en-US'); 
            var datetime = "Last Refresh: " + currentdate
            state.lastRefreshDateTime_Wallet=datetime;
        },
        resetdate_explorer(state){
            var currentdate = new Date().toLocaleString('en-US'); 
            var datetime = "Last Refresh: " + currentdate
            state.lastRefreshDateTime_Explorer=datetime;
        },
        clearWalletFilter(state){
            state.walletFilter={
                lookupType:'1',
                lookupFirst:'',
                lookupLast:'',
                lookupEmail:'',
                lookupBadge:'',
                showInactiveWallets:false,
            }
        }
    },
    getters:{
        getField,
    },
    actions:{
        beforeAPI(context){
            context.commit('resetbeforeapi'); 
        },
        setCurrentDateTime_Wallet(context){
            context.commit('resetdate_wallet')
        },
        setCurrentDateTime_Explorer(context){
            context.commit('resetdate_explorer')
        },
        clear_walletfilter(context){
            context.commit('clearWalletFilter')
        }
    }
})