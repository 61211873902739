<template>
  <div>
      <div class="filterDiv">
        <select name="" id="" class='entrySelect' v-model="restrictToLocation" v-if="showLocationFilter" >
            <option v-for="loc in locationList" :key="loc.id" :value="loc.id" >
                {{loc.name}}
            </option>
        </select>
        <select name="" id="" class='entrySelect' v-model="accountId" v-if="showAccountFilter" >
            <option v-for="account in accountListForFilter" :key="account.ID" :value="account.ID" >
                {{account.Name}}
            </option>
        </select>
        <select name="" id="" class='entrySelect' v-model="accountTransactionTypeId">
            <option v-for="transType in accountTransactionTypeList" :key="transType.id" :value="transType.id" >
                {{transType.name}}
            </option>
        </select>

        <select name="" id="" class='entrySelect' v-model="walletTokenTypeId">
            <option v-for="tokenType in walletTokenTypeList" :key="tokenType.id" :value="tokenType.id" >
                {{tokenType.name}}
            </option>
        </select>
        <input type="date" class="entryDate" v-model="startDate"/>
        <label class="filtertext">to</label>
        <input type="date" class="entryDate"  v-model="toDate"/>
        <smallbutton buttonclass="filterButton" toplabel="Go" @click="goFilter()"/>
        <smallbutton buttonclass="filterButton" toplabel="Reset" @click="resetFilter()"/>
    </div>
   
    <div>
        <label class="messagelabel">{{responseMessage}}</label>
    </div>
    
    <!-- advanced -->
    <linkbutton :toplabel="filterToggleText" @click="toggleAdvancedFilter()"/>
    <div v-if="showAdvancedFilters">
        <div class="horizFlexWithWrap">
            <div class="horizFlexWithWrap checkfilters">
                <formentrycheck  label="include pending" v-model="includePending"/>
                <formentrycheck  label="include funded" v-model="includeFunded"/>
                <formentrycheck  label="include failed" v-model="includeFailed"/>
                <formentrycheck  label="include voided" v-model="includeVoided"/>
                <formentrycheck  label="include $0" v-model="includeZeroDollar"/>
            </div>
            <div class="vertFlex">
                <label class="entryLabel">Min $</label>
                <input type="number" label="" class="entryText"   v-model="min"/>
            </div>
            <div class="vertFlex">
                <label class="entryLabel">Max $</label>
                <input type="number" label="" class="entryText"   v-model="max"/>
            </div>
            <div class="vertFlex">
                <label class="entryLabel">External Trans ID</label>
                <input type="number" label="" class="entryText"   v-model="externalTransactionId"/>
            </div>
            <div class="vertFlex">
                <label class="entryLabel">Wallet ID</label>
                <input type="number" label="" class="entryText"   v-model="walletId"/>
            </div>
            <div class="vertFlex">
                <label class="entryLabel">Account ID</label>
                <input type="number" label="" class="entryText"   v-model="accountId"/>
            </div>
        
        
        </div>
        
    </div>
  </div>
</template>

<script>
import linkbutton from '../controls/linkbutton.vue'
import smallbutton from '../controls/button.vue'
import formentrycheck from '../controls/entrycheck.vue'
import datamethods from '../../datamethods.js'
import {mapFields} from 'vuex-map-fields'
export default {
    name:'transfilter',
    components:{
        linkbutton,
        smallbutton,
        formentrycheck
    },
    props:{
        selectedWalletId:{
            type:Number,
            default:-1
        },
        accountList:{
            type:Array,
        }
    },
    mixins:[datamethods],
    data(){
        return{


            showAdvancedFilters:false,
            
  
        }
    },
    mounted(){

        if(this.walletTokenTypeList.length==0){
            console.log('starting filter')
            this.getTokenTypes()
            this.getTransactionTypes()
            this.getLocations()
            this.clearFilter();
        }
        if(this.lastFilterWalletId!=this.selectedWalletId){
            console.log('in mounted...new id')
            this.resetFilter()
            this.lastFilterWalletId=this.selectedWalletId
        }
        
    },
    computed:{
        ...mapFields([
            'filter.walletId',
            'filter.walletTokenTypeId',
            'filter.accountTransactionTypeId',
            'filter.accountId',
            'filter.startDate',
            'filter.toDate',
            'filter.min',
            'filter.max',
            'filter.externalTransactionId',
            'filter.includePending',
            'filter.includeFunded',
            'filter.includeVoided',
            'filter.includeFailed',
            'filter.includeZeroDollar',
            'filter.restrictToLocation',
            'accountTransactionTypeList',
            'walletTokenTypeList',
            'locationList',
            'lastFilterWalletId',
            'responseMessage',
            'pleaseWait',
            'pleaseWaitText'

        ]),
        accountListForFilter(){
            
            var acct={ID:-1,Name:'--All Accounts--'}
        
            this.accountList.splice(0,0,acct); 
            return this.accountList
        },
        filterToggleText(){
            if(this.showAdvancedFilters){
                return "hide advanced filters"
            }else{
                return "show advanced filters"
            }
        },
        showAccountFilter(){
            return this.selectedWalletId!=-1
        },
        showLocationFilter(){
            return true
        },
        today(){
            var t = new Date();
            var dd = String(t.getDate()).padStart(2, '0');
            var mm = String(t.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = t.getFullYear();

            return yyyy + '-' + mm + '-' + dd
        },
    },
    methods:{
        
        beforeAPI(){
            this.pleaseWait=true
            this.message=''
        },
        toggleDetail(trans){
            trans.showDetail=!trans.showDetail
        },
        transClass(trans){
         
            if(trans.isActive){return ' trans activeTrans'}
            else{return 'trans inactiveTrans'}

        },
         toggleAdvancedFilter(){
            this.showAdvancedFilters=!this.showAdvancedFilters
        },
        
        resetFilter(){
            this.clearFilter()
            this.goFilter()
        },
        clearFilter(){
            //clear filter and give it the given wallet id

                this.walletId=this.selectedWalletId
                this.accountId=-1
                this.walletTokenTypeId=-1
                this.walletTokenId=-1
                this.accountTransactionTypeId=-1
                this.startDate=this.today
                this.toDate=this.today
                this.min=null
                this.max=null
                this.externalTransactionId=null
                this.includePending=true
                this.includeFunded=true
                this.includeFailed=false
                this.includeVoided=false
                this.includeZeroDollar=true
                
            
        },
        goFilter(){
            
         
            this.responseMessage=''
            /* this.$emit('gofilter',this.filter) */
            this.$emit('gofilter')
        },
        async getTransactionTypes(){
            
            //setup request
            let req = {
                includeInactive:false
            }
            //call api
            this.beforeAPI();
            var response = await this.callAPI(req,'transactiontype')
            //handle response
            if(response.Successful){
                this.accountTransactionTypeList=response.list
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        async getTokenTypes(){
            
            //setup request
            let req = {
                includeInactive:false
            }
            //call api
            this.beforeAPI();
            var response = await this.callAPI(req,'wallettokentype')
            //handle response
            if(response.Successful){
                this.walletTokenTypeList=response.list
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        async getLocations(){
            
            //call api
            this.beforeAPI();
            var response = await this.callAPI(null,'locationswithall')
            //handle response
            if(response.Successful){
                this.locationList=response.locationList
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
    }
}
</script>

<style scoped>
.checkfilters{
    width:500px;
}
input{
    font:inherit;
    height:30px;
}
.filterDiv{
    display:flex;
    flex-direction: row;
    margin-bottom:10px;
}
.filterButton{
    background: var(--button-color);
    color:var(--light-text-color);
    border: none;
    border-radius:10px;
    min-width:80px;
    height:30px;
    padding:0px;
    font-size: medium;
    margin:5px;
}
.entryText{
    margin:5px;
    width:100px;

}
.entryDate{
    margin:5px;
    width:95vw;
    
}
.entryLabel{
    
    margin:0px 0px 0px 5px;
}
.entrySelect{

   
    height:33px;
}
.filtertext{
    
    margin: 15px 5px 0px 5px;
}

.horizFlexWithWrap{
    display:flex;
  flex-direction: row;
  margin:0px;
  flex-wrap: wrap;
}
</style>