<template>
    <div :class="divClass">
        <div v-for="dataitem in dataListFiltered" :key="dataitem.label" :value="dataitem.value" class="" >
            <label>{{dataitem.label}}:</label>
            <label>{{dataitem.value}}</label>
        </div>
    </div>
  
</template>

<script>
export default {
    name:'labeledlist',   
    props:{
        dataList:{
            type:Array,
            
        },
        isVertical:{
            type:Boolean,
            default:true
        },
        showNulls:{
            type:Boolean,
            default:false
        }
    },  
    computed:{
        divClass(){
            if(this.isVertical){return 'itemDiv'}
            else{return 'itemDivHoriz'}
        },
        dataListFiltered(){
            if(this.showNulls){return this.dataList }
            var selected=[];
            this.dataList.forEach(myFunction4)

            function myFunction4(item, index, arr) {
                let dv=arr[index]
                if(dv.value!=null){
          
                    selected.splice(0,0,dv)
                }
            }   
       
            return selected;

        },
    }
}
</script>

<style scoped>
.itemDiv{
    margin:5px;
}
.itemDivHoriz{
 
    display:flex;
    flex-direction: row;
    margin:5px;

}
label{
    font-size: small;
    margin-right:5px;
}
.pair{
    display:flex;
    flex-direction: row;
}
</style>