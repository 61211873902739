<template>
  <div :class="mainedgeClass">
        <div class="leftColumn">
            <div class="horizFlex">
                <label for="" class="labelBold right10">{{currentWallet.Name}} </label>
                 <label for="" class="labelBold right10" v-if="isMyWallet && isAdmin">(My Wallet)</label>
                <updateparent :personId="currentWallet.personId" @refresh="$emit('refresh')" v-if="currentWallet.IsActive"/>
            </div>
            <label class="label" >{{'#' + currentWallet.ID}}</label>
        </div>
        <div class="rightColumn">
             <confirmwithreason :showButton="trueParam" body="Do you want to reactivate this wallet?" title="Reactivate Wallet" v-model="deactivateReason" @yes="ReactivateWallet()" v-if="isAdmin && !currentWallet.IsActive"/>
            
            <updatepassword :isAdminChangePassword="true" oldpassword="dbbuilder" :username="currentWallet.email" 
                title="Reset Password" v-if="isAdmin && currentWallet.IsActive"/>
            <confirmwithreason :showLink="trueParam" body="Do you want to deactivate this wallet?" title="Deactivate Wallet" v-model="deactivateReason" @yes="DeactivateWallet()" v-if="isAdmin && !isMyWallet && currentWallet.IsActive"/>
            <smallbutton class="detailbutton" :toplabel="detailLabel"  @click="toggleDetail" v-if="showDetailButton"/>
        </div>
        <div class="itemdetail" v-if="showDetail">
            <ul class="listleft">
                <li v-for="token in currentWallet.walletTokenList" :key="token.ID" :value="token.ID">
                <div class="horizFlex">
                    <p class="tokenname">{{token.Name}}</p>
                    <confirm :showImageButton="trueParam" :body="getRemoveTokenString(token.Name)" title="Remove Token" @yes="RemoveToken(token.ID)" v-if="currentWallet.IsActive"/>
                </div>
                
                </li>
                
            </ul>
            
            <div class="topBorderDiv horizFlex">   
                <em class="note right10">{{currentWallet.message}}</em>
                <walletnote :walletId="currentWallet.ID" @refresh="$emit('refresh')"/>
            </div>
            

            <!-- ADD BUTTONS -->
            <div class="horizFlex">
                <newtoken :personId="currentWallet.personId" @refresh="$emit('refresh')" v-if="showAddToken"/>
                <smallbutton toplabel="Upgrade to Admin"   @click="upgradeToAdmin()" v-if="isAdmin && currentWallet.IsActive" :disabled="currentWallet.isAdmin"/>
            </div>
            <div v-if="responseMessage!=null">
                <label class="responsemessage">{{responseMessage}}</label>
            </div>
        </div>  
        
    </div>
    
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../datamethods.js'
import smallbutton from '../controls/button.vue'
import linkbutton from '../controls/linkbutton.vue'
import updateparent from '../actions/updateparent.vue'
import walletnote from '../actions/walletnote.vue'
import updatepassword from '../actions/updatepassword.vue'
import confirm from '../actions/confirm.vue'
import confirmwithreason from '../actions/confirmwithreason.vue'
import newtoken from '../actions/newtoken.vue'
export default {
    name:"wallet",
    mixins:[datamethods],
    components:{
        smallbutton,
        linkbutton,
        updateparent,
        walletnote,
        updatepassword,
        confirm,
        newtoken,
        confirmwithreason
    },
    props:{
        
    },
    data() {
        return {
            trueParam:true,
            showDetail:true,
            showDetailButton:false,
            responseMessage:'',
            addingAccount:false,
            addingCard:false,
            addingBank:false,

            deactivateReason:'',
        };
    },
    mounted(){
        console.log('inmount')
        if(this.alwaysopen=='true'){
            this.showDetailButton=false
            this.showDetail=true}
        
    },
    computed:{
        showAddToken(){
            return (this.isMyWallet || !this.disableAddFunds) && this.currentWallet.IsActive && this.showAddFunds
        },
        mainedgeClass(){
            var c ="mainedge"
            if(this.currentWallet.IsActive){c = c + " active"}
            else{c=c+" inactive"}
            return c
        },
        ...mapFields([
            'currentWallet',
            'loggedInUser.isAdmin',
            'disableAddFunds',
            'showAddFunds',
            'loggedInUser.walletId'
        ]),
        
        detailLabel(){
            if (this.showDetail){
                return "Hide Detail"
            }else{
                return "Show Detail"
            }
        },
        isMyWallet(){
            return this.walletId==this.currentWallet.ID
        }
    },
    methods:{
        toggleDetail(){
            this.showDetail=!this.showDetail;
        },
        getRemoveTokenString(tokenname){
            return 'Do you want to remove ' + tokenname + '?'
        },
        async upgradeToAdmin(){
            this.responseMessage=null
            let req = {
                wallet:this.currentWallet
            }
            var response = await  this.callAPI(req,'wallettoadmin')
            if(response.Successful){
                this.responseMessage = "You successfully upgraded this person to an admin"
        
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        async RemoveToken(tokenID){
            this.responseMessage=null
            console.log('inremove')
            let req = {
                walletTokenId:tokenID
            }
            var response = await this.callAPI(req,'removewallettokenbyid')
            if(response.Successful){
                this.$emit('refresh')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        async DeactivateWallet(){
            this.responseMessage=null
            let req = {
                id:this.currentWallet.ID,
                reason:this.deactivateReason
            }
            var response = await this.callAPI(req,'deactivatewallet')
            if(response.Successful){
                this.$emit('refresh')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        async ReactivateWallet(){
            this.responseMessage=null
            let req = {
                id:this.currentWallet.ID,
                reason:this.deactivateReason
            }
            var response = await this.callAPI(req,'reactivatewallet')
            if(response.Successful){
                this.$emit('refresh')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
    }
}
</script>

<style >

.mainedge{

 
    border-radius:5px;
    margin:10px;
    padding:10px;
    display:grid;
    grid-template-columns:1fr auto 1fr;
    grid-template-rows:1fr auto;
    grid-template-areas: 
    "left space right"
    "detail detail detail";
}
.active{
    background-color: var(--frame-color);
}
.inactive{
    background-color:var(--inactive-color);
}
.leftColumn{
    grid-area:left;
    display:flex;
    flex-direction: column;
    text-align: left;
}

.itemdetail{
    grid-area:detail;
    text-align: left;

}
.labelBold{
  font-size:large;
  font-weight: bold;
}

.detailbutton{
    width:120px;
    margin-left:auto;
}
.topBorderDiv{
    border-top:1px solid darkgray;
    padding-top:5px;
}
</style>
