<template>
<div>
    <smallbutton toplabel="Update Account Type" @click="startUpdateAccountType()" :buttonclass="buttonclass"/>
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="mealplanform">
                <label class="titlelabel">Update Account Type</label>
                <br/>
                <p class="subtitleBold">{{MealPlan.name}}</p>

                <div class="entry">
                <div class="entryTextDiv">
                <label class="entryLabel" for="">Select Account Type</label>
                </div>
                <select name="" id="" class='entrySelect' v-model="MealPlan.accountTypeId" required>
                    <option v-for="accountType in accountTypeList" :key="accountType.ID" :value="accountType.ID" >
                        {{accountType.Name}}
                    </option>
                </select>
                </div>
                <smallbutton  @click="saveAccountType()" toplabel="Save" />
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
  
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
            </form>
           
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
export default {
    name:'updateaccounttype',
    components:{
        smallbutton,
        actionmodal,
        formentry
    },
    props:{
        account:{
            type:Object,
            required:true
        },
        buttonclass:{
            type:String
        }
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            parent:{},
            responseMessage:'',

             MealPlan:{
                accountId:0,
                name:'',
                checkNumber:'',
                mealPlanAmount:'0',
                notes:'',
                decliningBalanceAmount:'0',
                asOfDateTime:'',
                accountTypeId:0
            },
            accountTypeList:[],
            accountTypeListMeal:[],
            enableSaveMealPlan:true,
        }
    },
    methods:{
         beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        startUpdateAccountType(){
            this.getAccountTypes();
            this.MealPlan={
                accountId:this.account.ID,
                name:this.account.Name,
                checkNumber:'',
                mealPlanAmount:'0',
                notes:'',
                decliningBalanceAmount:'0',
                asOfDateTime:'',
                accountTypeId:0,
                reprocessPOSTickets:false
            }
            this.$refs.mymodal.openModal()
        },
         async saveAccountType(){
            if(this.MealPlan.accountTypeId==0){
                this.responseMessage="Please select an Account Type"
                return
            }

            let req = this.MealPlan;
            var response = await this.callAPI(req,'updateaccounttype')
            
            if(response.Successful){
                
                this.responseMessage="Update Account Type suceeded."
                this.$emit('refresh')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        
        async getAccountTypes(){
            var response = await this.callAPI(null,'accounttype')
            
            if(response.Successful){
                this.accountTypeList = response.AccountTypeList
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
    
    
    }
}
</script>

<style>

</style>





























