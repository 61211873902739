<template>
    <div>
        <linkbutton toplabel="Void Transaction" @click="Void()"/>
        <!-- ACCT NOTE MODAL -->
          <actionmodal ref="voidModal"> 
            <template v-slot:body>
                <label class="titlelabel">Void Transaction</label>
                <label class="">Do you want to void this transaction?</label>
                <div>
                    <smallbutton  @click="closeVoidModal" toplabel="Void"/>
                    <smallbutton  @click="$refs.voidModal.closeModal()" toplabel="Cancel"/>
                </div>
                <div>
                  <label class="responsemessage">{{message}}</label>
                </div>
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import datamethods from '../../datamethods.js'
    import smallbutton from '../controls/button.vue'
    import actionmodal from './actionmodal.vue'
    import linkbutton from '../controls/linkbutton.vue'
    export default {
        name:'transvoid',
        components:{
            smallbutton,
            actionmodal,
            linkbutton
        },
        props:{
            accountId:{
                type:Number,
                required:true
            },
            transactionId:{
                type:Number,
                required:true
            }
            
        },
        mixins:[datamethods],
        data(){
            return{
                message:''
            }
        },
        methods:{
            Void(){
    
                this.$refs.voidModal.openModal()
            },
            async closeVoidModal(){
                this.message=''
                let req = {
                    accountId:this.accountId,
                    accountTransactionId:this.transactionId,
                    note:this.NewNote
                }
                var response = await this.callAPI(req,'void')
                if(response.Successful){
                    this.$refs.voidModal.closeModal()
                    this.$emit('refresh')
                }else{
                    this.message = response.Message
                }
            }
        }
    }
    </script>
    
    <style>
    
    </style>