<template>
<div>
    <smallbutton toplabel="Add Product Transaction" @click="startAdd()" v-if="showButton" class="button buttonpay"/>
    <linkbutton toplabel="Add Product Transaction" @click="startAdd()" v-if="!showButton"/>
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="balform">
                <label class="titlelabel">Add Product Transaction for {{account.Name}}</label>
                <br/>
                
                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Select Product Type</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="productSale.restrictToProductTypeGlobalKey" required @change="getProducts()" >
                        <option v-for="ptype in ProductTypeList" :key="ptype.guid" :value="ptype.guid" >
                            {{ptype.name}}
                        </option>
                    </select>
                </div>
                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Select Product</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="productSale.restrictToProductGlobalKey" required @change="selectProduct()">
                        <option v-for="p in ProductList" :key="p.guid" :value="p.guid" >
                            {{p.name}} ( {{p.price | toCurrency}})
                        </option>
                    </select>
                </div>


                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Select Payment Method</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="productSale.walletTokenId" required @change="selectToken()">
                        <option v-for="token in currentWallet.walletTokenList" :key="token.ID" :value="token.ID" >
                            {{token.Name}}
                        </option>
                    </select>
                </div>
                
                <div class="vertFlex">
                    <label class="listtextsub" v-if="productSale.taxAmount!='0'">
                    Tax: {{productSale.taxAmount | toCurrency}}
                </label>
                <label class="listtextsub" v-if="productSale.feeAmount!='0'">
                    Fee: {{productSale.feeAmount | toCurrency}}
                </label>
                <label class="listtextbold" >
                    Total: {{TotalAmount | toCurrency}}
                </label>
                </div>
                
             


                <div>
                    <label for="" class="required">{{requiredFieldLabel}}</label>
                </div>
                <smallbutton  @click="saveTrans()" toplabel="Save" />
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
                
            </form>
            
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import {mapFields} from 'vuex-map-fields'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import linkbutton from '../controls/linkbutton.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
export default {
    name:'addproductrans',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton
    },
    props:{
        account:{
            type:Object,
            required:true
        },
        showButton:{
            type:Boolean,
            default:false
        }
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            
            responseMessage:'',
            pleaseWait:false,
            ProductTypeList:[],
            ProductList:[],
            productSale: {
                walletId:-1,
                accountId:-1,
                locationId:-1,
                restrictToProductTypeGlobalKey:'',
                restrictToProductGlobalKey:'',
                price:0,
                walletTokenId:-1,
                productId:-1,
                feeAmount:0,
                taxAmount:0
            },
        } 
    },
    computed:{
        ...mapFields([
            'disableAddFunds',
            'currentWallet',
            'loggedInUser'

        ]),
        TotalAmount(){
            return this.productSale.feeAmount + this.productSale.taxAmount + this.productSale.price
        }
    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        startAdd(){
            this.responseMessage = ""
            this.productSale.walletId=this.currentWallet.ID
            this.productSale.accountId=this.account.ID;
            this.productSale.restrictToProductTypeGlobalKey=''
            this.productSale.restrictToProductGlobalKey=''
            this.productSale.price=0
            this.productSale.walletTokenId=-1
            this.productSale.productId=1
            this.productSale.feeAmount=0
            this.productSale.taxAmount=0
            this.getProductTypes()
            this.$refs.mymodal.openModal()
        },
        
        async getProductTypes(){
            let req = {
                walletId:this.currentWallet.ID,
                locationId:-1
            }
            this.beforeAPI();
            var response = await this.callAPI(req,'producttypes')

            //handle response
            if(response.Successful){
            this.ProductTypeList = response.productTypeList
    
            if(this.ProductTypeList.length==1){
                //select the type to skip it
            
                this.getProducts(this.productTypeList[0].guid)
                
            }
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        async getProducts(){
            var guid = this.productSale.restrictToProductTypeGlobalKey
            console.log('get prod ' + guid)
            this.selectedProductTypeGUID=guid
            let req = {
                walletId:this.currentWallet.ID,
                locationId:-1,
                productTypeGUID:guid
            }
            this.beforeAPI();
            var response = await this.callAPI(req,'products')

            //handle response
            if(response.Successful){
            this.ProductList = response.productList
            
            }else{
            this.message = response.Message
            }
            this.pleaseWait=false;
        },
       selectProduct(){
           var price=0;
           var id=-1;
           let guid = this.productSale.restrictToProductGlobalKey
           this.ProductList.forEach(myFunction2)

            function myFunction2(item, index, arr) {
                let acct=arr[index]
                if (acct.guid==guid){
                    price=acct.price
                    id=acct.id
                }
            }
            this.productSale.price=price
            this.productSale.productId=id
       },
       async selectToken(){
        this.GetTax()
       },
       async GetTax(){
 
            //create req
            let req = {
                Product:{
                    id:this.productSale.productId
                }
                
            }
            //call
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'gettax')
            //handle response
            if(response.Successful){
                var taxamount = response.responseDouble
                console.log(taxamount)
                //add the tax to the record  
                this.productSale.taxAmount=taxamount
            
                this.GetFees();//now we can add the fees!
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        async GetFees(){

            let req = 
                this.productSale
                
            
            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'getfee_byamount')
            //handle response
            if(response.Successful){
                this.productSale.feeAmount=response.responseDouble

            
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        async saveTrans(){
     
            //send in to start transaction
            let req = this.productSale
            this.beforeAPI();
            var response = await this.callAPI(req,'productsale_admin')

            //handle response
            if(response.Successful){
            
            this.responseMessage="Product Transaction Completed"
            
            }else{
            this.message = response.Message
            }
            this.pleaseWait=false;

        
            
        },
    },
    mounted(){
        
    }
       
}
</script>

<style>

</style>





























