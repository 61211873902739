<template>
  <div :class="transedgeclass" @click="$emit('click')">
        <div class="leftColumn">
            <label for="" class="labelBold" v-if="isfinancialtrans">
                {{formatAmountString(transaction.amount, transaction.amount_fee,transaction.TransactionTypeName)}} 
            </label>
            <label for="" class="labelBold" v-if="!isfinancialtrans">
                {{formatNonAmountString(transaction.amount)}} 
            </label>
            <label class="label" v-if="isfinancialtrans">
               {{formatFeeAmountString(transaction.amount_fee)}}
            </label>
            <label class="label"  >
               {{transaction.effectiveDateTime | formatDate}}
            </label>

            <div v-if="transaction.fileName!=null">
                <div v-if="fileisviewable">
                     <transdoc :filename="transaction.fileName" :linkname="getlinkname"/>
                </div>    
                <div v-if="!fileisviewable">
                    <a :href="getfile" target="_blank">{{getlinkname}}</a>
                </div>
            </div>
            
            <div class="messagetext">
                <em class="note">{{transaction.message}}</em>
            </div>
            
            <div class="horizFlex" v-if="isfinancialtrans">
                <transreceipt :transactionId="transaction.ID"/>
            </div>
            <div class="horizFlex" v-if="isRefundable">
                <refundcard :transaction="transaction" @refresh="$emit('refresh')"/>
            </div>
            <!-- <div v-if="transaction.viewDetailLink!=null">
                <a :href="transaction.viewDetailLink" target="_blank">{{transaction.viewDetailName}}</a>
            </div> -->
        </div>
        <div class="rightColumn">
            <label class="labelBold">
                {{transaction.walletTokenName}}
            </label>
            <label class="label">
                {{'Transaction ID: ' + transaction.ID}}
            </label>
            <transnote :accountId="transaction.accountId" :transactionId="transaction.ID" @refresh="$emit('refresh')"/>
        </div>  
    </div>
</template>

<script>
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import linkbutton from '../controls/linkbutton.vue'
import transreceipt from '../actions/transreceipt.vue'
import transnote from '../actions/transnote.vue'
import transdoc from '../actions/doc.vue'
import refundcard from '../actions/refundcard.vue'
import {mapFields} from 'vuex-map-fields'
export default {
    name:"transactionview",
    props:{
        transaction:{
            type:Object,
            required:true
        },

    },
    mixins:[format],
    components:{
        smallbutton,
        linkbutton,
        transreceipt,
        transnote,
        transdoc,
        refundcard
    },
    data() {
        return {
           showborder:true,
        };
    },
    methods:{
        formatAmountString(amt, fee,type){
            amt =this.$options.filters.toCurrency(amt)
            fee =this.$options.filters.toCurrency(fee)
            return type + ' Amount: ' + amt 
        },
        formatNonAmountString(amt){
            var transtypename = this.transaction.TransactionTypeName
            var transname = this.transaction.Name
            var doctypename = this.transaction.documentTypeName
            var out = transtypename 
            if(transname!=null){out = out + ' - ' + transname}
            if(doctypename!=null){out = out + ' - ' + doctypename}
            if(amt!=null){
                amt =this.$options.filters.toCurrency(amt)
                out = out + ' Amount: ' + amt 
            }
            return out
        },
        formatFeeAmountString(amt){
            amt =this.$options.filters.toCurrency(amt)
        
            return ' Fee: ' + amt
        },
    },
    computed:{
        isRefundable(){
            if(window.SHOW_CARD_REFUND==undefined){return false}
            else{return window.SHOW_CARD_REFUND && this.transaction.walletTokenName !=null && this.transaction.amount>0}
        },
        transedgeclass(){
            
            var c = "transedge"
            if(this.transaction.TransactionTypeName=="Payment"){
                c = c + " darkgreen"
            }else if(this.transaction.TransactionTypeName=="Add"){
                c= c + " darkblue"
            }else if(this.transaction.TransactionTypeName=="Refund"){
                c= c + " darkred"
            }else{
                c=c + " darktan"
            }
            return c
        },
        ...mapFields([
            
            'imagepath'
        ]),
        isfinancialtrans(){
            return this.transaction.affectsBalance
        },
        getfile(){
            return this.imagepath + this.transaction.fileName
        },
        getlinkname(){
            if(this.transaction.documentTypeName==null){
                return "View Document"
            }else{ return "View " + this.transaction.documentTypeName}
        },
        fileisviewable(){
            var ext = this.getextension(this.transaction.fileName)
            if(ext=='.jpg' || ext=='.png' || ext=='.svg'){
                return true
            }else{
                return false
            }
            
        },
    }
}
</script>

<style  >
a{
    color:var(--button-color);
}
.transedge{
    
    background-color: white;
    border-radius:10px;
    margin:10px 0px 10px 0px;
    padding:20px;
    display:grid;
    grid-template-columns:1fr auto 1fr;
    grid-template-rows:auto;
    grid-template-areas: 
    "left space right";
    border-style:solid;
    /* border-color:var(--border-color); */
    border-width: 4px;
}
.darkgreen{
    border-color:rgb(61,118,81);
}

.darkblue{
    border-color:rgb(79,118,221);
}

.darkred{
    border-color:rgb(234,134,134);
}

.darktan{
    border-color:rgb(207,189,148);
}

.leftColumn{
    grid-area:left;
    display:flex;
    flex-direction: column;
    text-align: left;
}
.rightColumn{
    grid-area:right;
    display:flex;
    flex-direction: column;
    margin-left:10px;
    text-align:right;
}
.labelBold{
  font-size:large;
  font-weight: bold;
}
.label{
    
}

</style>
