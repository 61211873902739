<template>
<div>
    <smallbutton toplabel="Grant Access to Another Wallet" @click="startGrantAccess()"/>
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="balform" v-on:keyup.enter="lookupWalletForGrant"  v-on:submit.prevent>
                <label class="titlelabel">Grant Access to Another Wallet</label>
                <br>
                <label class="subtitleBold">1. Select account(s) to grant access to</label>
                <br/>
                <div class="horizFlex topMargin centered" v-for="account in accountListAccess" :key="account.ID" :value="account.ID">
                    <input class="entryCheckbox" type="checkbox" v-model="account.selected" >
                    <label for="" class="entryCheckLabel">{{account.Name}}</label>
                </div>
                <div class="vertFlex">
                <label class="subtitleBold">2. Lookup Wallet to grant access to</label>
                <formentrystacked inputtype="email" label="" v-model="lookupWalletEmail" placeholder="Email address" />
                <formentrystacked inputtype="number" label="" v-model="lookupWalletID" placeholder="Wallet ID" />
                
                
                </div>
                <div>
                <smallbutton toplabel="Lookup" @click="lookupWalletForGrant()"  />
                </div>
                <label class="subtitleBold">3. Select Wallet(s) to grant access to</label>
                <br/>
                <div class="horizFlex topMargin centered" v-for="person in walletresults" :key="person.ID" :value="person.ID">
                    <input class="entryCheckbox" type="checkbox" name="myparents" v-model="person.selected" >
                    <label for="" class="entryCheckLabel">{{person.Name}}</label>
                </div>

                
                <smallbutton  @click="ConfirmGrantAccess()" toplabel="Grant Access" />
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
              
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
            </form>
            
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentrystacked from '../controls/entry-stacked.vue'
export default {
    name:'grantaccess',
    components:{
        smallbutton,
        actionmodal,
        formentrystacked
    },
    props:{

        account:{
            type:Object,
            required:true
        }
    },
    mixins:[datamethods,format],
    data(){
        return{
            
            responseMessage:'',
            pleaseWait:false,

            accountListAccess:[],

            lookupWalletEmail:'',
            lookupWalletID:'',
            walletresults:[],
            showAfterGrantButtons:false,
        }
    },
    computed:{
        ...mapFields([
            'currentWallet',

        ]),
        
    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
         startGrantAccess(){
            this.showAfterGrantButtons=false
            let inaccount = this.account
            var acctList=[]
            this.currentWallet.accountList.forEach(myFunction2)
            
            function myFunction2(item, index, arr) {
                let acct=arr[index]
                acct.selected=inaccount.ID==acct.ID;
                acctList.splice(0,0,acct); 
            }          
            this.accountListAccess=acctList;        
            this.$refs.mymodal.openModal()
        },
        async ConfirmGrantAccess(){
            if(this.$refs.balform.checkValidity()){
                this.beforeAPI();
                let req = {
                    originalWalletID:this.currentWallet.ID,
                    accountList:this.accountListAccess,
                    walletList:this.walletresults
                }
                var response = await this.callAPI(req,'grantaccess')
            
                if(response.Successful){
                    this.responseMessage="Grant Access was successful"
                    this.showAfterGrantButtons=true
                }else{
                    this.message = response.Message
                }
                this.pleaseWait=false;
            }
         },
        async lookupWalletForGrant(){
      
            this.beforeAPI();
            let req = {
                person:{EmailAddress:this.lookupWalletEmail,
                walletID:this.lookupWalletID}
            }

            var response = await this.callAPI(req,'lookupbyemail')
      
            if(response.Successful){
                var wList=[]
                response.WalletList.forEach(myFunction3)

                function myFunction3(item, index, arr) {
                    let w=arr[index]
                    w.selected=false;
                    wList.splice(0,0,w); 
                }          

                this.walletresults=wList;
                if(wList.length==0){
                    this.message="None found. Please try again"
                }else{
                    this.responseMessage = response.Message
                }
                this .pleaseWait=false;
            
            }
        }
    }
}
</script>

<style>
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
</style>





























