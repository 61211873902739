<template>
<div>
    <div class="horizFlex wrap">
        <smallbutton toplabel="Add A Card" @click="startCard()" :disabled="disableAddFunds" class="button buttonpay"/>
        <smallbutton toplabel="Add A Bank Account" @click="startBank()" :disabled="disableAddFunds" class="button buttonpay" v-if="achAvailable"/>
        <smallbutton toplabel="Add A Bank Account" @click="startBankHosted()" :disabled="disableAddFunds" class="button buttonpay" v-if="achHostedAvailable"/>
        <smallbutton toplabel="Add A Paypal Account" @click="startPaypal()" :disabled="disableAddFunds" class="button buttonpay" v-if="paypalAvailable"/>
        <smallbutton toplabel="Add A Venmo Account" @click="startVenmo()" :disabled="disableAddFunds" class="button buttonpay" v-if="venmoAvailable"/>
    </div>
    
    <!-- MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="bankform">
                <label class="subtitleBold">Add Bank Account</label>
                <formentry inputRef="firstaccount" inputtype="number" isrequired="True" label="Bank Routing Number" placeholder="maximum 9 digits"  v-model="NewAccount.RoutingNumber" />
                <formentry inputtype="number" isrequired="True" label="Bank Account Number" placeholder="maximum 15 digits" max="999999999999999" v-model="NewAccount.AccountNumber" />
                <formentry label="Bank Account Nickname"/>
    
                <smallbutton  @click="saveAccount()" :disabled="saveButtonEnabled==false" toplabel="Save" />
                <smallbutton  @click="cancel()" toplabel="Cancel"/>

                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
            </form>
        </template>
      </actionmodal>


        <!-- MODAL -->
      <modal ref="modalName">
        <template v-slot:header>
          <div class="horizFlex">
            <p class="titlelabel">Add Payment Method</p>
            <img alt="cardlogo" :src="cardLogoPath"  class="logocard">
          </div>
          
        </template>

        <template v-slot:body>
          <p class="subtitle"> {{confirmMessage}}
            If you have a transaction or daily limit less than this transaction amount, please contact your credit card company or bank to pre-approve this transaction before submitting payment.
            Your school's address is: {{locationAddress}}. Your receipt will remain on file for a 12 month period and a copy of your 
            receipt can be provided by contacting {{locationEmail}}.</p>
       
          <p class="subtitleBold"> 
            When you click “I Accept,” you will be redirected to the Card Processor's Hosted Payments secure card entry site. 
            Please enter your card number, expiration date and security code. Then, wait for a confirmation message 
            that will bring you back to SchoolVision to complete the funding of your student school account(s).</p>
        
          <div class="center">
            <smallbutton toplabel="I Decline"  @click="$refs.modalName.closeModal()"></smallbutton>
            <smallbutton toplabel="I Accept" @click="closeMyModalOK"></smallbutton>
          </div>
        </template>
      </modal>



    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
import modal from '../controls/modal.vue'
export default {
    name:'newtoken',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        modal
    },
    props:{
        personId:{
            type:Number,
            required:true
        },
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            responseMessage:'',
            pleaseWait:false,

            addingCard:false,
            addingBank:false,
            addingHostedBank:false,
            addingPaypal:false,
            addingVenmo:false,
            
            cardLogoPath:require('@/assets/card.jpg'),
            NewAccount:{
                RoutingNumber:'',
                AccountNumber:'',
            },
            locationPhone:window.LOCATION_PHONE,
            locationAddress:window.LOCATION_ADDRESS,
            locationEmail:window.LOCATION_EMAIL,
      
        }
    },
    computed:{
        ...mapFields([
            'disableAddFunds',

        ]),
        achAvailable(){
            if(window.ACH_AVAILABLE==undefined){return false}
            else{return window.ACH_AVAILABLE=="True"}
        },
        achHostedAvailable(){
            if(window.ACH_HOSTED_AVAILABLE==undefined){return false}
            else{return window.ACH_HOSTED_AVAILABLE=="True"}
        },
        paypalAvailable(){
            if(window.PAYPAL_AVAILABLE==undefined){return false}
            else{return window.PAYPAL_AVAILABLE=="True"}
        },
        venmoAvailable(){
            if(window.VENMO_AVAILABLE==undefined){return false}
            else{return window.VENMO_AVAILABLE=="True"}
        },
    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
         startCard(){
            this.addingCard=true;
            this.$refs.modalName.openModal()
        },
        startBankHosted(){
            this.addingHostedBank=true;
            this.$refs.modalName.openModal()
        },
        startVenmo(){
            this.addingVenmo=true;
            this.$refs.modalName.openModal()
        },
        startPaypal(){
            this.addingPaypal=true;
            this.$refs.modalName.openModal()
        },
        startBank(){
            this.addingBank=true;
            this.$refs.modalName.openModal()
        },
        async closeMyModalOK(){
            this.$refs.modalName.closeModal()
            if(this.addingCard){
                this.addingCard=false
                await this.addCard()
            }else if(this.addingBank){
                this.addingBank=false
                this.addAccount()
            }else if(this.addingHostedBank){
                this.addingHostedBank=false
                await this.addHostedAccount('ach')
            }else if(this.addingPaypal){
                this.addingPaypal=false
                await this.addHostedAccount('paypal')
            }else if(this.addingVenmo){
                this.addingVenmo=false
                await this.addHostedAccount('venmo')
            }
            
        },
        async addCard(){
            var windowReference = window.open('about:blank','_blank');
            this.beforeAPI();
            var response = await this.addWalletToken(this.personId)
            
            if(response.Successful){
                console.log(response.walletUrl)
                windowReference.location=response.url;
            }else{
                this.message = response.Message
                windowReference.close()
            }
            this.pleaseWait=false;
        },
        async addHostedAccount(tokentype){
            var windowReference = window.open('about:blank','_blank');

            this.beforeAPI();
            let req = {
                locationId:'0',
                personId:this.personId,
                walletTokenType:tokentype
            }
            var response = await this.callAPI(req,'addwallettoken_bytype')
            
            if(response.Successful){
                windowReference.location=response.url;
            }else{
                this.message = response.Message
                windowReference.close()
            }
            this.pleaseWait=false;
        },
        addAccount(){
            this.$refs.mymodal.openModal()
            this.$nextTick(()=>{
                this.$refs.firstaccount.focus();
            });
        },
        async saveAccount(){
            if(this.$refs.bankform.checkValidity()){
                this.beforeAPI();
                let req = {
                    personId:this.personId,
                    walletToken:this.NewAccount
                }
           
                var response = await this.callAPI(req,'addwallettokenach')
                
                if(response.Successful){
                    this.$refs.mymodal.closeModal()
                    this.NewAccount={};
                    this.$emit('refresh')
                }else{
                    console.log("save acct error " + response.Message)
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
            }
        },
    }
}
</script>

<style>
.logocard{
  max-width: 100%;
    max-height:75px;
    margin-left:80px;
}
.center{
    text-align:center;
}
</style>





























