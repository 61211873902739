<template>
    <div>

        <pencilplusbutton  @click="Start()" />
        <!-- MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <label class="titlelabel">Update Guest Alert</label>
                <textarea v-model="newAlert.note"  rows="3"/>
                <div>
                    <smallbutton  @click="closeModal" toplabel="Save"/>
                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Cancel"/>
                </div>
                <div>
                  <label class="responsemessage">{{responseMessage}}</label>
                </div>
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import datamethods from '../../datamethods.js'
    import smallbutton from '../controls/button.vue'
    import actionmodal from './actionmodal.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import pencilplusbutton from '../controls/pencilplusbutton.vue'
    export default {
        name:'updateguestalert',
        components:{
            smallbutton,
            actionmodal,
            linkbutton,
            pencilplusbutton
        },
        props:{
            guestAlert:{
                type:Object,
                required:true
            },
            personId:{
                type:Number,
                required:true
            }
        },
        mixins:[datamethods],
        data(){
            return{
                newAlert:{
                    guestId:0,
                    guestAlertId:0,
                    note:null,
                    enteredDateTime:null,
                    enteredBy:0,
                    isActive:true,
                    isAlert:true,
                    personId:0
                },
                responseMessage:''
            }
        },
        methods:{
            Start(){
                if(this.guestAlert!=null){
                    this.newAlert=this.guestAlert
                }
                this.newAlert.personId=this.personId
                this.$refs.mymodal.openModal()
            },
            async closeModal(){
                this.responseMessage=''
                let req = {
                    GuestNote:this.newAlert
                }
                var response = await this.callAPI(req,'guestalert')
                if(response.Successful){
                    this.$refs.mymodal.closeModal()
                    this.$emit('refresh')
                }else{
                    this.responseMessage = response.Message
                }
            }
        }
    }
    </script>
    
    <style>
    
    </style>