<template>
<div>
    <smallbutton toplabel="Merge Accounts" @click="GoToMergeAccounts()"/>
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="mergeform">
                <label class="titlelabel">1. Select 2 Accounts to Merge</label>
                <br/>
                <div class="horizFlex topMargin centered" v-for="account in accountListAccess" :key="account.ID" :value="account.ID">
                    <input class="entryCheckbox" type="checkbox" v-model="account.selected" >
                    <label for="" class="entryCheckLabel">{{account.Name}}</label>
                </div>
                
                
                <label class="subtitleBold ">2. Select Student/Account to remain after merge</label>
                <br/>
                <label class="subtitle nomargin">(All transactions and balances will be preserved)</label>
                <br/>
                <select name="" id="" class='entrySelect selaccount' v-model="accountToRemain" required>
                    <option v-for="account in accountListAccessSelected" :key="account.ID" :value="account.ID" >
                        {{account.Name}}
                    </option>
                </select>
                
                <div>
                <smallbutton  @click="ConfirmMergeAccount()" toplabel="Merge Accounts" />
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                </div>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
                
            </form>
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
export default {
    name:'mergeaccounts',
    components:{
        smallbutton,
        actionmodal,
        formentry
    },
    props:{

        
    },
    mixins:[datamethods,format],
    data(){
        return{
            
            responseMessage:'',
            pleaseWait:false,

            accountListAccess:[],


            accountToRemain:0,
        }
    },
    computed:{
        ...mapFields([
            'currentWallet',

        ]),
        accountListAccessSelected(){
            var count=0
            var selected=[];
            this.accountListAccess.forEach(myFunction4)

            function myFunction4(item, index, arr) {
                let acct=arr[index]
                if(acct.selected==true){
                count=count+1;
                selected.splice(0,0,acct)
                }
            }   
            console.log(count)
            return selected;

        },
    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        GoToMergeAccounts(){
            var acctList=[]
            this.currentWallet.accountList.forEach(myFunction2)

            function myFunction2(item, index, arr) {
                let acct=arr[index]
                acct.selected=false;
                acctList.splice(0,0,acct); 
            }          
            this.accountListAccess=acctList;        

            this.$refs.mymodal.openModal()
            this.responseMessage=''
        },
        async ConfirmMergeAccount(){
            if(this.selectedAccountCount()!=2){
                this.responseMessage="Please select 2 accounts"
                return;
            }
            if(this.accountToRemain==0){
                this.responseMessage="Please select the student/account to remain"
                return;
            }
            this.beforeAPI();
            let req = {
                accountList:this.accountListAccess,
                accountToRemain:this.accountToRemain
            }
            var response = await this.callAPI(req,'mergeaccounts')
           
            if(response.Successful){
                this.responseMessage="Merge Accounts was successful"
                this.$emit('refresh')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
            
        },
        selectedAccountCount(){
            var count=0
        
            this.accountListAccess.forEach(myFunction4)

            function myFunction4(item, index, arr) {
                let acct=arr[index]
                if(acct.selected==true){
                count=count+1;

                }
            }   
            console.log(count)

            return count; 
        },
        
    }
}
</script>

<style>
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
</style>





























