<template>
<div>
    <pencilbutton @click="startUpdateMealPlan()"/>
    <!-- <linkbutton toplabel="Update MealPlan" @click="startUpdateMealPlan()"/> -->
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="mealplanform">
                <label class="titlelabel">Update Meal Plan</label>
                <br/>
                <p class="subtitleBold">{{AccountPref.name}}</p>

                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Select Meal Plan</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="account.mealplan" >
                        <option v-for="mealplan in MealPlanList" :key="mealplan.Name" :value="mealplan.Name" >
                            {{mealplan.Name}}
                        </option>
                    </select>
                </div>
                <smallbutton @click="saveMealPlan()" toplabel="Save"/>
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
      
            </form>
           
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
import linkbutton from '../controls/linkbutton.vue'
import pencilbutton from '../controls/pencilbutton.vue'
export default {
    name:'updateMealPlan',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton,
        pencilbutton
    },
    props:{
        account:{
            type:Object,
            required:true
        }
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            
            responseMessage:'',
            pleaseWait:false,
            MealPlanList:[]
        
        }
    },
    mounted(){
        this.getMealPlanList();
    },
    computed:{
        ...mapFields([
            'currentWallet',

        ]),
        
    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        startUpdateMealPlan(account){
            this.AccountPref={accountId:this.account.ID,
                name:this.account.Name,
                walletId:this.currentWallet.ID,
                walletTokenId:this.account.MealPlanId}
            this.$refs.mymodal.openModal()
        },
        async saveMealPlan(){
            let req = {
                
                    personId:this.account.personId,
                    mealPlanName:this.account.mealplan
                
            }
            this.beforeAPI();
            var response = await this.callAPI(req,'updatemealplan')
           
            if(response.Successful){
                this.responseMessage="Update Meal Plan was successful"
                this.$emit('refresh')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },  
        async getMealPlanList(){
            console.log('getMealPlans')
            
            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(null,'mealplanlist')

            //handle response
            if(response.Successful){
                this.MealPlanList = response.MealPlanList
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
    }
}
</script>

<style>

</style>





























