<template>
<div>
    <pencilbutton  @click="startUpdateRefill()" :disabled="disableAddFunds"/>
    <!-- <linkbutton toplabel="Update Refill" @click="startUpdateRefill()" :disabled="disableAddFunds"/> -->
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="myform">
                <label class="titlelabel">Update Refill Settings</label>
                <formentry inputRef="firstrefill" inputtype="number"  label="Refill when balance below"   v-model="AccountToUpdate.refillWhenBelowXDollars" />
                <formentry inputtype="number" isrequired="True" label="Refill with"  v-model="AccountToUpdate.refillWithXDollars" />
    
                <smallbutton  @click="saveRefill()" toplabel="Save"/>
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
                
            </form>
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import {mapFields} from 'vuex-map-fields'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import linkbutton from '../controls/linkbutton.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
import pencilbutton from '../controls/pencilbutton.vue'
export default {
    name:'updaterefill',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton,
        pencilbutton
    },
    props:{
        account:{
            type:Object,
            required:true
        }
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            
            responseMessage:'',
            pleaseWait:false,

           AccountToUpdate:{}
        }
    },
    computed:{
        ...mapFields([
            'disableAddFunds',

        ]),
        
    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        startUpdateRefill(){

      
            this.AccountToUpdate.ID=this.account.ID
            this.AccountToUpdate.refillWhenBelowXDollars=this.account.refillWhenBelowXDollars
            this.AccountToUpdate.refillWithXDollars=this.account.refillWithXDollars
            this.$refs.mymodal.openModal()
            this.responseMessage=''
        },
        async saveRefill(){
            
            if(this.$refs.myform.checkValidity()){
                
                this.beforeAPI();
                 let req = {
                     Account:this.AccountToUpdate
                }
                var response = await this.callAPI(req,'updaterefill')

                if(response.Successful){
                    this.responseMessage="Update Refill was successful"
                    this.$emit('refresh')
                }else{
                this.message = response.Message
                }
                this.pleaseWait=false;
            }
         },
       
        
    }
}
</script>

<style>

</style>





























