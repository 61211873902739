<template>
  <div class="horizFlexStretch">
    <labeledlist :dataList="getDetail()"/>
    <div class="buttonDiv" >
        <transnote :accountId="trans.accountId" :transactionId="trans.transactionId" @refresh="$emit('refresh')"/>
        <!-- <smallbutton toplabel="Go to Wallet" @click="goToWallet(trans.walletId)" /> -->
        <transreceipt :transactionId="trans.transactionId"/>
        <div class="horizFlex" v-if="isRefundable">
            <refundcard_transaction :transaction="trans" @refresh="$emit('refresh')"/>
        </div>
        <!-- <transvoid :accountId="trans.accountId" :transactionId="trans.transactionId" @refresh="$emit('refresh')" v-if="isPayment"/> -->
    </div>
  </div>
</template>

<script>
import labeledlist from '../controls/labeledlist.vue'
import transnote from '../actions/transnote.vue'
import smallbutton from '../controls/button.vue'
import transreceipt from '../actions/transreceipt.vue'
import transvoid from '../actions/transvoid.vue'
import refundcard_transaction from '../actions/refundcard_transaction.vue'
export default {
    name:'transdetail',
    components:{
        labeledlist,
        transnote,
        smallbutton,
        transreceipt,
        transvoid,
        refundcard_transaction
    },
    props:{
        trans:{
            type:Object,
            
        },
    },  
    computed:{
        isPayment(){
            var ispay = this.trans.detailType=='Credit' || this.trans.detailType=='Debit' || this.trans.detailType=='ACH' 
                    || this.trans.detailType=='Paypal' || this.trans.detailType=='Venmo'
            return ispay
        },
        isRefundable(){
            if(window.SHOW_CARD_REFUND==undefined){return false}
            else{return window.SHOW_CARD_REFUND && this.trans.walletTokenName !=null && this.trans.amount>0}
        },
    },
    methods:{
        getDetail(){
            if(this.isPayment){
                return this.getPaymentDetail()
            }else if(this.trans.detailType=='POS'){
                return this.getPOSDetail()
            }else{
                return this.getOtherDetail()
            }
        },
        
        getPaymentDetail(){
            var detail = [
                {
                    label:'Payment Method',
                    value:this.trans.walletTokenName
                },
                {   
                    label:'Transaction Details',
                    value:this.trans.transactionDetails
                },
                {
                    label:'Fee Transaction Details',
                    value:this.trans.transactionDetails_Fee
                },
                {
                    label:'Funded Date',
                    value:this.trans.fundedDate
                },
                {
                    label:'Fee Funded Date',
                    value:this.trans.feeFundedDate
                },
                {
                    label:'Status',
                    value:this.trans.statusName
                },
                {
                    label:'Notes',
                    value:this.trans.notes
                }
            ]
            return detail
        },
        getPOSDetail(){
            var detail = [
                {   
                    label:'Receiver Name',
                    value:this.trans.receiverName
                },
                {
                    label:'POS Transaction ID',
                    value:this.trans.posTransactionID
                },
                {
                    label:'POS Details',
                    value:this.trans.posDetails
                },
                {
                    label:'Notes',
                    value:this.trans.notes
                }
            ]
            return detail
        },
        getOtherDetail(){
            var detail = [
                {
                    label:'Notes',
                    value:this.trans.notes
                }
            ]
            return detail
        },
        goToWallet(walletId){
            this.$emit('gotowallet',walletId)
        }
    }
}
</script>

<style>
.buttonDiv{
    display:flex;
    flex-direction: column-reverse;
    margin:0px 20px 10px 0px;
    text-align: right;
}
.horizFlexStretch{
  display:flex;
  flex-direction: row;
  margin:0px 0px 0px 10px ;
  justify-content:space-between;
  padding-left:25px;
}
</style>