<template>
<div>
    <smallbutton toplabel="Add Meal Plan" @click="startAddMealPlan()" :buttonclass="buttonclass"/>
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="mealplanform">
                <label class="titlelabel">Add Meal Plan to Account</label>
                <br/>
                <p class="subtitleBold">{{MealPlan.name}}</p>

                <div class="entry">
                <div class="entryTextDiv">
                <label class="entryLabel" for="">Select Account Type</label>
                </div>
                <select name="" id="" class='entrySelect' v-model="MealPlan.accountTypeId" required>
                    <option v-for="accountType in accountTypeListMeal" :key="accountType.ID" :value="accountType.ID" >
                        {{accountType.Name}}
                    </option>
                </select>
                </div>
                <formentry   label="Check Number"  v-model="MealPlan.checkNumber" />
                <formentry inputtype="number"  label="Meal Plan Amount"  v-model="MealPlan.mealPlanAmount" />
                <formentry inputtype="number"  label="Declining Balance Amount"  v-model="MealPlan.decliningBalanceAmount" />
                <formentry inputtype="date"  label="As Of Date"  v-model="MealPlan.asOfDateTime" isrequired='True'/>
                <formentry  label="Notes"  v-model="MealPlan.notes" />
                <div class="horizFlex ">
                    <input class="entryCheckbox" type="checkbox" v-model="MealPlan.reprocessPOSTickets" >
                    <label for="" class="mediumtext">Reprocess POS Tickets</label>
                </div>
                <smallbutton  @click="saveNewMealPlan()" toplabel="Save" :disabled="!enableSaveMealPlan"/>
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Cancel"/>
                <div v-if="pleaseWait">
                    <label for="" class="pleasewait">{{pleaseWaitText}}</label>
                </div> 
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
            </form>
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
export default {
    name:'newmealplan',
    components:{
        smallbutton,
        actionmodal,
        formentry
    },
    props:{
        account:{
            type:Object,
            required:true
        },
        buttonclass:{
            type:String
        }
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            parent:{},
            responseMessage:'',

             MealPlan:{
                accountId:0,
                name:'',
                checkNumber:'',
                mealPlanAmount:'0',
                notes:'',
                decliningBalanceAmount:'0',
                asOfDateTime:'',
                accountTypeId:0
            },
            accountTypeList:[],
            accountTypeListMeal:[],
            enableSaveMealPlan:true,
        }
    },
    methods:{
         beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        startAddMealPlan(){
            this.getAccountTypesMeal();
            this.MealPlan={
                accountId:this.account.ID,
                name:this.account.Name,
                checkNumber:'',
                mealPlanAmount:'0',
                notes:'',
                decliningBalanceAmount:'0',
                asOfDateTime:'',
                accountTypeId:0,
                reprocessPOSTickets:false
            }
            this.enableSaveMealPlan=true
            this.$refs.mymodal.openModal()
        },
        async saveNewMealPlan(){
            this.beforeAPI()
            if(this.MealPlan.accountTypeId==0){
                this.responseMessage="Please select an Account Type"
                return
            }
            if(this.MealPlan.asOfDateTime==''){
                this.responseMessage="Please select a date"
                return
            }
            var amtsok=this.checkMealPlanAmounts();
            console.log(amtsok)
            if(amtsok==false){
                this.responseMessage="Please enter positive dollar amounts less than $10,000"
                return
            }
            let req = this.MealPlan;
            
            var response = await this.callAPI(req,'addmealplan')

            if(response.Successful){
                if(response.ResponseInteger>0 ){
                    this.responseMessage="Add Meal Plan Succeeded."
                    this.enableSaveMealPlan=false
                }else{
                    this.responseMessage="Add Meal Plan failed."
                }
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        async getAccountTypesMeal(){
            var response = await this.callAPI(null,'accounttypemeal')
            
            if(response.Successful){
                this.accountTypeListMeal = response.AccountTypeList
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
    checkMealPlanAmounts(){
      
      if(this.MealPlan.mealPlanAmount==''){return false}
      if(this.MealPlan.decliningBalanceAmount==''){return false}

      var amtInt = parseInt(this.MealPlan.mealPlanAmount);
      var balInt = parseInt(this.MealPlan.decliningBalanceAmount);
      console.log("check amts " + amtInt + ' ' + balInt)
      if(amtInt > 10000 || amtInt<0){ return false}
      if(balInt<0 || balInt > 10000){return false}

      return true;
    },
       
    
    }
}
</script>

<style>

</style>





























