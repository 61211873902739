<template>
<div>
    <smallbutton toplabel="Request Refund" @click="startRequestRefund()" v-if="showButton" class="button buttonpay"/>
    <linkbutton toplabel="Request Refund" @click="startRequestRefund()" v-if="!showButton"/>
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="balform">
                <label class="titlelabel">Request Refund for {{Refund.Name}} (Balance: ${{Refund.accountBalance}})</label>
                <br/>
                
                <formentry inputtype="number"  isrequired="True" label="Refund Amount $"  v-model="Refund.amountRequested"  />
                <formentry   label="Reason"  v-model="Refund.notes" />
                <div>
                <label for="" class="required">{{requiredFieldLabel}}</label>
                </div>
                <smallbutton  @click="refund()" toplabel="Request Refund" :disabled="disableRefund"/>
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
            </form>
            
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import {mapFields} from 'vuex-map-fields'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import linkbutton from '../controls/linkbutton.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
export default {
    name:'requestrefund',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton
    },
    props:{
        account:{
            type:Object,
            required:true
        },
        showButton:{
            type:Boolean,
            default:false
        }
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            trueParam:true,
            responseMessage:'',
            pleaseWait:false,

            Refund:{
                accountId:0,
                accountBalance:0,
                Name:'',
                walletId:0,
                walletTokenId:0,
                amountRequested:'0',
                requestedByAdmin:true,
                notes:''
            },
            disableRefund:false,
            
        }
    },
    computed:{
        ...mapFields([
            'disableAddFunds',
            'currentWallet'

        ]),
        
    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        startRequestRefund(){
            this.disableRefund=false
            this.Refund={
                accountId:this.account.ID,
                accountBalance:this.account.currentBalance,
                Name:this.account.Name,
                walletId:this.currentWallet.ID,
                walletTokenId:this.account.preferredWalletTokenId,
                amountRequested:'0',
                requestedByAdmin:true,
                notes:''
            }
            this.$refs.mymodal.openModal()
        },
        async refund(){
            //validate amt
            var amtreq = parseFloat(this.Refund.amountRequested)
            var amtbal = parseFloat(this.Refund.accountBalance)
            if(amtreq>amtbal){
                this.responseMessage="Please enter a refund amount less than or equal to the account balance"
                return;
            }
            if(amtreq<0){
                this.responseMessage="Please enter a positive refund amount."
                return;
            }
            this.beforeAPI();
            var response = await this.callAPI(this.Refund,'walletrefundwithamount')
            
            if(response.Successful){
                this.responseMessage="Request Refund was successful"
                this.$emit('refresh')
                this.disableRefund=true
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        
       
        
    }
}
</script>

<style>

</style>





























