import axios from "axios";
export const HTTP = axios.create({
    baseURL:window.VUE_CONFIG_API,
})

export const ReportHTTP = axios.create({
    baseURL:window.VUE_CONFIG_ReportAPI,
})

export const HTTPImage = axios.create({
    baseURL:window.VUE_CONFIG_API_IMAGE,
})