<template>
<div>
    <smallbutton toplabel="Reprocess Tickets" @click="startReprocess()" :buttonclass="buttonclass"/>
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="mealplanform">
                <label class="titlelabel">Reprocess Tickets for Account</label>
                <br/>
                <p class="subtitleBold">{{MealPlan.name}}</p>
                <formentry inputtype="date"  label="As Of Date"  v-model="MealPlan.asOfDateTime" isrequired='True'/>
            
                <smallbutton @click="reprocess()" toplabel="Reprocess"/>
                <smallbutton @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                <div v-if="pleaseWait">
                    <label for="" class="pleasewait">{{pleaseWaitText}}</label>
                </div> 
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
            </form>
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
export default {
    name:'reprocesstickets',
    components:{
        smallbutton,
        actionmodal,
        formentry
    },
    props:{
        account:{
            type:Object,
            required:true
        },
        buttonclass:{
            type:String
        }
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            parent:{},
            responseMessage:'',

             MealPlan:{
                accountId:0,
                name:'',
                checkNumber:'',
                mealPlanAmount:'0',
                notes:'',
                decliningBalanceAmount:'0',
                asOfDateTime:'',
                accountTypeId:0
            },
            accountTypeList:[],
            accountTypeListMeal:[],
            enableSaveMealPlan:true,
        }
    },
    methods:{
         beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        
        startReprocess(){
            this.MealPlan={
                accountId:this.account.ID,
                name:this.account.Name,
                checkNumber:'',
                mealPlanAmount:'0',
                notes:'',
                decliningBalanceAmount:'0',
                asOfDateTime:'',
                accountTypeId:0,
                reprocessPOSTickets:false
            }
            this.$refs.mymodal.openModal()
        },
        async reprocess(){
  
            if(this.MealPlan.asOfDateTime==''){
                this.responseMessage="Please select a date"
                return
            }
            this.beforeAPI()
            let req = this.MealPlan;
            var response = await this.callAPI(req,'reprocesstickets')
            
            if(response.Successful){
                this.responseMessage="Reprocess Tickets Succeeded"
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        
    }
}
</script>

<style>

</style>





























