<template>
<div>
    <pencilbutton @click="startUpdateReferredBy()"/>
    <!-- <linkbutton toplabel="Update ReferredBy" @click="startUpdateReferredBy()"/> -->
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="mealplanform">
                <label class="titlelabel">Update {{referredByLabel}}</label>
                <br/>
                <p class="subtitleBold">{{AccountPref.name}}</p>

                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Select {{referredBy}}</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="account.referredBy" >
                        <option v-for="r in referredByList" :key="r.Name" :value="r.Name" >
                            {{r.Name}}
                        </option>
                    </select>
                </div>
                <smallbutton @click="saveReferredBy()" toplabel="Save"/>
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
      
            </form>
           
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
import linkbutton from '../controls/linkbutton.vue'
import pencilbutton from '../controls/pencilbutton.vue'
export default {
    name:'updateReferredBy',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton,
        pencilbutton
    },
    props:{
        account:{
            type:Object,
            required:true
        }
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            
            responseMessage:'',
            pleaseWait:false,
            referredByList:[]
        
        }
    },
    mounted(){
        this.getReferredByList();
    },
    computed:{
        ...mapFields([
            'currentWallet',

        ]),
        referredByLabel(){

            if(window.REFERREDBY_LABEL==undefined){return "Referred By"}
            else{return window.REFERREDBY_LABEL}
   
        },
    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        startUpdateReferredBy(account){
            this.AccountPref={accountId:this.account.ID,
                name:this.account.Name,
                walletId:this.currentWallet.ID,
                walletTokenId:this.account.ReferredById}
            this.$refs.mymodal.openModal()
        },
        async saveReferredBy(){
            let req = {
                person:{
                    id:this.account.personId,
                    referredBy:this.account.referredBy
                }
            }
            this.beforeAPI();
            var response = await this.callAPI(req,'updatereferredby')
           
            if(response.Successful){
                this.responseMessage="Update was successful"
                this.$emit('refresh')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },  
        async getReferredByList(){
            console.log('getReferredBys')
            
            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(null,'referredby')

            //handle response
            if(response.Successful){
                this.referredByList = response.PersonList
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
    }
}
</script>

<style>

</style>





























