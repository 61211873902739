<template>
<div>
    <!-- <linkbutton toplabel="Add Wallet Note" @click="AddWalletNote()"/> -->
    <pencilplusbutton  @click="AddWalletNote()" />
    <!-- MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <label class="titlelabel">Add Wallet Note</label>
            <textarea v-model="newNote"  rows="3"/>
            <div>
                <smallbutton  @click="closeModal" toplabel="Save"/>
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Cancel"/>
            </div>
            <div>
              <label class="responsemessage">{{responseMessage}}</label>
            </div>
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import linkbutton from '../controls/linkbutton.vue'
import pencilplusbutton from '../controls/pencilplusbutton.vue'
export default {
    name:'walletnote',
    components:{
        smallbutton,
        actionmodal,
        linkbutton,
        pencilplusbutton
    },
    props:{
        walletId:{
            type:Number,
            required:true
        },
        
    },
    mixins:[datamethods],
    data(){
        return{
            newNote:'',
            responseMessage:''
        }
    },
    methods:{
        AddWalletNote(){

            this.NewNote=''
            this.$refs.mymodal.openModal()
        },
        async closeModal(){
            this.responseMessage=''
            let req = {
                walletId:this.walletId,
                note:this.newNote
            }
            var response = await this.callAPI(req,'addwalletnote')
            if(response.Successful){
                this.$refs.mymodal.closeModal()
                this.$emit('refresh')
            }else{
                this.responseMessage = response.Message
            }
        }
    }
}
</script>

<style>

</style>