<template>
<div>

    <linkbutton :toplabel="linkname" @click="start()"/>
  <!-- ACCT NOTE MODAL -->
      <modal ref="acctnoteModal">
        <template v-slot:header>
          <div class="receipttitlediv">
            <p class="receipttitle">{{linkname}}</p>
          </div>
        </template>
        <template v-slot:body>
          <img :src="getimage"/>
        </template>
        <template v-slot:footer>
          <div class="closeButton">
            <smallbutton  @click="$refs.acctnoteModal.closeModal()" toplabel="Close"/>
          </div>
          <div>
              <label class="responsemessage">{{message}}</label>
            </div>
        </template>
      </modal>
    </div>
</template>

<script>
import smallbutton from '../controls/button.vue'
import modal from '../controls/modal.vue'
import linkbutton from '../controls/linkbutton.vue'
import {mapFields} from 'vuex-map-fields'
export default {
    name:'transdoc',
    components:{
        smallbutton,
        modal,
        linkbutton
    },
    props:{
        filename:{
            type:String,
            required:true
        },
        linkname:{
            type:String
        }
        
    },
    data(){
        return{
            message:'',
        }
    },
    computed:{
        ...mapFields([
            'imagepath',

        ]),
        getimage(){
            console.log("imgpath" + this.imagepath + this.filename)
            return this.imagepath + this.filename
        }
    },
    methods:{
        start(){
            this.$refs.acctnoteModal.openModal()
        },
        
        
    }
}
</script>


<style>
.closeButton{
    display:flex;
    flex-direction: row-reverse;
}
.receipttext{
    font-size:medium;
    white-space:pre-wrap;
    text-align: center;
}
.receipttitle{
    font-size:large;
    text-align: center;
}
.receipttitlediv{
    text-align:center;
}
</style>