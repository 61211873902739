<template>
<div>
    <linkbutton toplabel="Add Note" @click="AddAccountNote()"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="acctnoteModal"> 
        <template v-slot:body>
            <label class="titlelabel">Add Transaction Note</label>
            <textarea v-model="NewNote"  rows="3"/>
            <div>
                <smallbutton  @click="closeAcctNoteModal" toplabel="Save"/>
                <smallbutton  @click="$refs.acctnoteModal.closeModal()" toplabel="Cancel"/>
            </div>
            <div>
              <label class="responsemessage">{{message}}</label>
            </div>
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../datamethods.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import linkbutton from '../controls/linkbutton.vue'
export default {
    name:'transnote',
    components:{
        smallbutton,
        actionmodal,
        linkbutton
    },
    props:{
        accountId:{
            type:Number,
            required:true
        },
        transactionId:{
            type:Number,
            required:true
        }
        
    },
    mixins:[datamethods],
    data(){
        return{
            NewNote:'',
            message:''
        }
    },
    methods:{
        AddAccountNote(){

            this.NewNote=''
            this.$refs.acctnoteModal.openModal()
        },
        async closeAcctNoteModal(){
            this.message=''
            let req = {
                accountId:this.accountId,
                accountTransactionId:this.transactionId,
                note:this.NewNote
            }
            var response = await this.callAPI(req,'addtransactionnote')
            if(response.Successful){
                this.$refs.acctnoteModal.closeModal()
                this.$emit('refresh')
            }else{
                this.message = response.Message
            }
        }
    }
}
</script>

<style>

</style>