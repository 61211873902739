<template>
    <div class="horizSpaced">
        <div class="bordered darkgreen">
            <label class="titletext">{{dataList[0].name}}</label>
            <div class="twocols">
                <summarybox :amount="dataList[1].amount" :count="dataList[1].count" :title="dataList[1].name" background="green"/>
                <summarybox :amount="dataList[2].amount" :count="dataList[2].count" :title="dataList[2].name"  background="green"/>
                
            </div>
            <div class="flexSpace">
                <label class="titletext">{{dataList[0].count}} {{dataList[0].name}}</label>
                <label class="titletext"> - {{dataList[0].amount | toCurrency}}</label>
            </div>
            
        </div>

        <div class="bordered darkblue">
            <label class="titletext">{{dataList[3].name}}</label>
            <div class="twocols">
                <summarybox :amount="dataList[4].amount" :count="dataList[4].count" :title="dataList[4].name"  background="blue"/>
                <summarybox :amount="dataList[5].amount" :count="dataList[5].count" :title="dataList[5].name" background="blue"/>
            </div>
            
            <div class="flexSpace">
                <label class="titletext">{{dataList[3].count}} {{dataList[3].name}}</label>
                <label class="titletext"> - {{dataList[3].amount | toCurrency}}</label>
            </div>
        </div>

        <div class="bordered darkred">
            <label class="titletext">{{dataList[6].name}}</label>
            <div class="twocols">
                <summarybox :amount="dataList[7].amount" :count="dataList[7].count" :title="dataList[7].name"  background="red"/>
                <summarybox :amount="dataList[8].amount" :count="dataList[8].count" :title="dataList[8].name" background="red"/>
            </div>
            
            <div class="flexSpace">
                <label class="titletext">{{dataList[6].count}} {{dataList[6].name}}</label>
                <label class="titletext"> - {{dataList[6].amount | toCurrency}}</label>
            </div>
        </div>

        <div class="bordered darktan">
            <label class="titletext">{{dataList[9].name}}</label>
            <div class="twocols">
                <summarybox :amount="dataList[10].amount" :count="dataList[10].count" :title="dataList[10].name" background="tan"/>
                <summarybox :amount="dataList[11].amount" :count="dataList[11].count" :title="dataList[11].name" background="tan"/>
            </div>
            
            <div class="flexSpace">
                <label class="titletext">{{dataList[9].count}} {{dataList[9].name}}</label>
                <label class="titletext"> - {{dataList[9].amount | toCurrency}}</label>
            </div>
        </div>

       <!--  <div class="col1">
            <div class="horizFlex">
                <label>{{dataList[0].label}}:</label>
                <label class="right">{{dataList[0].value }}</label>
            </div>
            <div class="horizFlex">
                <label>{{dataList[1].label}}:</label>
                <label class="right">{{dataList[1].value | toCurrency}}</label>
            </div>
            <div class="horizFlex">
                <label>{{dataList[2].label}}:</label>
                <label class="right">{{dataList[2].value }}</label>
            </div>
            <div class="horizFlex">
                <label>{{dataList[3].label}}:</label>
                <label class="right">{{dataList[3].value | toCurrency}}</label>
            </div>
            <div class="horizFlex">
                <label>{{dataList[4].label}}:</label>
                <label class="right">{{dataList[4].value }}</label>
            </div>
            <div class="horizFlex">
                <label>{{dataList[5].label}}:</label>
                <label class="right">{{dataList[5].value | toCurrency}}</label>
            </div>
        </div>
        <div class="col2">
            <div class="horizFlex">
                <label>{{dataList[6].label}}:</label>
                <label class="right">{{dataList[6].value }}</label>
            </div>
            <div class="horizFlex">
                <label>{{dataList[7].label}}:</label>
                <label class="right">{{dataList[7].value | toCurrency}}</label>
            </div>
            <div class="horizFlex">
                <label>{{dataList[8].label}}:</label>
                <label class="right">{{dataList[8].value }}</label>
            </div>
            <div class="horizFlex">
                <label>{{dataList[9].label}}:</label>
                <label class="right">{{dataList[9].value | toCurrency}}</label>
            </div>
        </div>
        <div class="col3">
            <div class="horizFlex">
                <label>{{dataList[10].label}}:</label>
                <label class="right">{{dataList[10].value }}</label>
            </div>
            <div class="horizFlex">
                <label>{{dataList[11].label}}:</label>
                <label class="right">{{dataList[11].value | toCurrency}}</label>
            </div>
            <div class="horizFlex">
                <label>{{dataList[12].label}}:</label>
                <label class="right">{{dataList[12].value }}</label>
            </div>
            <div class="horizFlex">
                <label>{{dataList[13].label}}:</label>
                <label class="right">{{dataList[13].value | toCurrency}}</label>
            </div>
            <div class="horizFlex">
                <label>{{dataList[14].label}}:</label>
                <label class="right">{{dataList[14].value }}</label>
            </div>
            <div class="horizFlex">
                <label>{{dataList[15].label}}:</label>
                <label class="right">{{dataList[15].value | toCurrency}}</label>
            </div>
        </div> -->
       <!--  <div class="col4">
            <div class="horizFlex">
                <label>{{dataList[16].label}}:</label>
                <label class="right">{{dataList[16].value }}</label>
            </div>
            <div class="horizFlex">
                <label>{{dataList[17].label}}:</label>
                <label class="right">{{dataList[17].value | toCurrency}}</label>
            </div>
            <div class="horizFlex">
                <label>{{dataList[18].label}}:</label>
                <label class="right">{{dataList[18].value }}</label>
            </div>
            <div class="horizFlex">
                <label>{{dataList[19].label}}:</label>
                <label class="right">{{dataList[19].value | toCurrency}}</label>
            </div>
        </div> -->
        <!-- <div v-for="dataitem in dataList" :key="dataitem.label" :value="dataitem.value" class="">
            <label>{{dataitem.label}}:</label>
            <label>{{dataitem.value}}</label>
        </div> -->
    </div>
  
</template>

<script>
import format from '../../format.js'
import summarybox from '../controls/summarybox.vue'
export default {
    name:'transsummary',   
    props:{
        dataList:{
            type:Array,
            
        },

    },  
    computed:{

    },
    mixins:[format],
    components:{
        summarybox
    }

}
</script>

<style scoped>

.boxed{
    border:darkgray 1px solid;
    display:flex;
    flex-direction: column;
    padding:10px;
    margin:5px;
}
.bordered{
    border-radius:10px;
    display:flex;
    flex-direction: column;
    padding:15px;
    margin-right:20px;
}
.darkgreen{
    background-color:rgb(61,118,81);
}

.darkblue{
    background-color:rgb(79,118,221);
}

.darkred{
    background-color:rgb(234,134,134);
}

.darktan{
    background-color:rgb(207,189,148);
}

.titletext{

    color:white;
}
.flexwrap{
    display:flex;
    flex-direction: row;
    
}
.twocols{
    display:grid;
    grid-template-columns: auto auto auto auto;

}
.horizSpaced{
 width:97vw;
  display:flex;
  flex-direction: row;
  margin:0px;
  justify-content: space-between;
  flex-wrap:wrap;
}
.itemDiv{
 
    display:grid;
    width:100vw;
    grid-template-columns:repeat(4,1fr);
    grid-template-rows:auto auto auto auto;
    margin-bottom:3px;
    text-align: left;
 
}
label{
 
    margin-right:5px;
    min-width:160px;
}
.pair{
    display:flex;
    flex-direction: row;
}
.right{
    text-align: right;
    min-width: 80px;
}
</style>