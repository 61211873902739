<template>
<div>
    <pencilbutton @click="startUpdateTuitionType()"/>
    <!-- <linkbutton toplabel="Update TuitionType" @click="startUpdateTuitionType()"/> -->
    <!--  MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <form ref="mealplanform">
                <label class="titlelabel">Update Tuition Type</label>
                <br/>
                <p class="subtitleBold">{{AccountPref.name}}</p>

                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">Select Tuition Type</label>
                    </div>
                    <select name="" id="" class='entrySelect' v-model="account.tuitionPaymentTypeId" @change="selectTuitionPaymentType()">
                        <option v-for="tuition in tuitionPaymentTypeList" :key="tuition.id" :value="tuition.id" >
                            {{tuition.name}}
                        </option>
                    </select>
                </div>
                 <!-- if sponsor, allow entry -->
                 <formentry  inputtype="text"  label="Sponsor Name"  v-model="account.sponsor"   v-if="account.tuitionPaymentTypeName=='Sponsor'"/>
                <smallbutton @click="saveTuitionType()" toplabel="Save"/>
                <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                <div>
                    <label class="responsemessage">{{responseMessage}}</label>
                </div>
      
            </form>
           
            
        </template>
      </actionmodal>
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentry from '../controls/entry.vue'
import linkbutton from '../controls/linkbutton.vue'
import pencilbutton from '../controls/pencilbutton.vue'
export default {
    name:'updateTuitionType',
    components:{
        smallbutton,
        actionmodal,
        formentry,
        linkbutton,
        pencilbutton
    },
    props:{
        account:{
            type:Object,
            required:true
        }
        
    },
    mixins:[datamethods,format],
    data(){
        return{
            
            responseMessage:'',
            pleaseWait:false,
            tuitionPaymentTypeList:[]
        
        }
    },
    mounted(){
        this.getTuitionPaymentTypeList();
    },
    computed:{
        ...mapFields([
            'currentWallet',

        ]),
        
    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.responseMessage=''
        },
        selectTuitionPaymentType(){
            var name
            var id=this.account.tuitionPaymentTypeId
            this.tuitionPaymentTypeList.forEach(myFunction2)

                function myFunction2(item, index, arr) {
                    let acct=arr[index]
                    if(acct.id==id){
                        name = acct.name
                    }
                }
            this.account.tuitionPaymentTypeName=name
        },
        startUpdateTuitionType(account){
            this.responseMessage=''
            this.AccountPref={accountId:this.account.ID,
                name:this.account.Name,
                walletId:this.currentWallet.ID,
                walletTokenId:this.account.tuitionTypeId}
            this.$refs.mymodal.openModal()
        },
        async saveTuitionType(){
            let req = {
                person:{
                    id:this.account.personId,
                    tuitionPaymentTypeId:this.account.tuitionPaymentTypeId,
                    sponsor:this.account.sponsor
                }
            }
            this.beforeAPI();
            var response = await this.callAPI(req,'updatetuitionpaymenttype')
           
            if(response.Successful){
                this.responseMessage="Update Tuition Type was successful"
                this.$emit('refresh')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },  
        async getTuitionPaymentTypeList(){
            console.log('gettuitiontypes')
            
            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(null,'gettuitionpaymenttype')

            //handle response
            if(response.Successful){
                this.tuitionPaymentTypeList = response.TuitionPaymentTypeList
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
    }
}
</script>

<style>

</style>





























