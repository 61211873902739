<template>
    <div>
        <pencilbutton  @click="start()"  />
        <!-- <linkbutton toplabel="Update Initial Due" @click="start()" :disabled="disableAddFunds"/> -->
        <!--  MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <form ref="balform">
                    <label class="titlelabel">Set Charging Limit for {{account.Name}}</label>
                    <br/>
                    <label class="mediumtext">School Default: {{defaultAllow | toCurrency}}</label>
                    <br/>
                    
                    <label class="mediumtext" >{{ oldOverride }}</label>
                    <formentry inputtype="number"  isrequired="True" label="Updated Charging Limit $"  v-model="updatedAccount.allowXOverBalanceOverride"  />
                    
                    <div>
                    <label for="" class="required">{{requiredFieldLabel}}</label>
                    </div>
                    <smallbutton  @click="save()" toplabel="Save"/>
                    <smallbutton  @click="$refs.mymodal.closeModal()" toplabel="Close"/>
                    <div>
                        <label class="responsemessage">{{responseMessage}}</label>
                    </div>
                </form>
                
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import datamethods from '../../datamethods.js'
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import actionmodal from './actionmodal.vue'
    import formentry from '../controls/entry.vue'
    import pencilbutton from '../controls/pencilbutton.vue'
    export default {
        name:'updateoverbalanceoverride',
        components:{
            smallbutton,
            actionmodal,
            formentry,
            linkbutton,
            pencilbutton
        },
        props:{
            account:{
                type:Object,
                required:true
            }
            
        },
        mixins:[datamethods,format],
        data(){
            return{
                defaultAllow:0,
                responseMessage:'',
                pleaseWait:false,
    
                updatedAccount:{
                    ID:0,
                    allowXOverBalanceOverride:0
                },
                
            }
        },
        computed:{
            ...mapFields([
                'disableAddFunds',
                'loggedInUser.isAdmin'
            ]),
            oldOverride(){
                if(this.account.allowXOverBalanceOverride<0){
                    return "Old Override Amount: None"
                }else{
                    var old = this.$options.filters.toCurrency(this.account.allowXOverBalanceOverride)
                    return "Old Override Amount: " + old
                }
                
            }
        },
        methods:{
            beforeAPI(){
                this.pleaseWait=true
                this.responseMessage=''
            },
            start(){
                this.getDefault();
          
                this.updatedAccount={
                   ID:this.account.ID,
                   allowXOverBalanceOverride:this.allowXOverBalanceOverride
                }        
               
                this.$refs.mymodal.openModal()
                this.responseMessage=''
            },
            async save(){
                if(this.$refs.balform.checkValidity()){

                    //if not admin, can't be more than the school default
                    if(!this.isAdmin){
                        if(this.updatedAccount.allowXOverBalanceOverride > this.defaultAllow){
                            var def = this.$options.filters.toCurrency(this.defaultAllow)
                            this.responseMessage="Please enter a Charging Limit less than the school default of " + def
                            return;
                        }
                    }

                     this.beforeAPI();
                      let req = {
                        account:this.updatedAccount,
                    }
                    var response = await this.callAPI(req,'accountoverbalanceoverride')
    
                    if(response.Successful){
                        this.responseMessage="Update Charging Limit was successful"
                        this.$emit('refresh')
                    }else{
                    this.message = response.Message
                    }
                    this.pleaseWait=false;
                }
             },
             
             async getDefault(){
       
                     this.beforeAPI();
                      let req = {
                        account:this.updatedAccount,
                    }
                    var response = await this.callAPI(req,'accountoverbalance')
    
                    if(response.Successful){
                        this.defaultAllow = response.responseDouble
                    }else{
                    this.message = response.Message
                    }
                    this.pleaseWait=false;
                
             },
        }
    }
    </script>
    
    <style>
    
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    